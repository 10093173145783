import React, { useContext, useEffect } from 'react';
import './Glassmorphism.css';
//import { Gradient } from 'whatamesh';
import { AuthContext } from './AuthContext';

const Glassmorphism = () => {
  const { user } = useContext(AuthContext);
/*
    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient("#gradient-canvass");
    });*/

  return (
      <div className="overlay-container">
      {/*<canvas id ="gradient-canvass"></canvas>*/}
        <div className="overlay">
        <div className="overlay__inner">
            <h1 className="overlay__title">Elevate Your Coding Skills: Engaging Lessons Meet Practical Coding Challenges</h1>
            <p className="overlay__description">Discover a seamless blend of engaging, detailed lessons and programming challenges designed to build your coding foundation and effectively apply your newfound knowledge in Python.</p>
            <div className="overlay__btns">

              <button className="globe-header-button">
                  {user && user.stripeSubscriptionId ? (
                      <span className="globe-header-button-span"><a className="globe-header-button-text" href="/dashboard">Dashboard</a></span>
                  ) : (
                      <span className="globe-header-button-span"><a className="globe-header-button-text" href="/pricing">Buy Now</a></span>
                  )}
              </button>

            </div>
        </div>
        </div>
    </div>
  );
};

export default Glassmorphism;
