import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Navbar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import Modal from './Modal';
import { AuthContext } from './AuthContext';
import CustomGoogleLoginButton from './CustomGoogleLoginButton';

const Navbar = () => {

  //const [user, setUser] = useState(null);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, login, logout } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  /* for modal implementation
  useEffect(() => {
  const loggedInUser = localStorage.getItem('user');
  if (loggedInUser) {
    setUser(JSON.parse(loggedInUser));
  }
  }, []);

    useEffect(() => {
    // Check if the query parameter 'login' is present
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('login') === 'true' && !user) {
      openModal();
    }
  }, [location, user]);*/

  /* works perfect
  const handleLoginSuccess = async (response) => {
    console.log(response);
    //const token = response.credential;
    const token = response.access_token;
    console.log(token);
    try {
      const res = await axios.post('http://localhost:4000/api/verify-token', { token });
      const userData = res.data.user;
      setUser(userData); // Update the user state with data from your backend
      login(userData, token);
      localStorage.setItem('user', JSON.stringify(userData));
      closeModal();
    } catch (error) {
      console.error('Error verifying token:', error);
    }
  };*/
/* works also 
  const handleLogoutSuccess = () => {
    googleLogout();
    logout();
    setUser(null);
    localStorage.removeItem('user');
  };
*/
const handleLogoutSuccess = async () => {
    try {
        //console.log("Sending logout request for user:", user._id); // Log the logout attempt
        //const response = await axios.post('http://localhost:4000/api/logout', { userId: user._id });
        //console.log("Logout response:", response.data); // Log the response from the server

      googleLogout();
      console.log("Logged out of Google session successfully");
        logout();
        //setUser(null);
      localStorage.removeItem('user');
      window.location.href = '/';
    } catch (error) {
        console.error('Error during logout:', error); // Log any errors during logout
    }
};


  
    useEffect(() => {
        const navEl = document.querySelector('.navbar');

        window.addEventListener('scroll', () => {
            if (window.scrollY >= 56) {
                navEl.classList.add('bg-light')
                navEl.classList.remove('navbar-dark');
            }
            else if (window.scrollY < 56) {
                navEl.classList.add('navbar-dark');
                navEl.classList.remove('bg-light')
            }
        })
    })

  const handleNavbarToggle = () => {
    const nav_element = document.querySelector('.navbar');
    
    if (window.scrollY < 56) {
      if (nav_element.classList.contains('bg-light')) {
        nav_element.classList.remove('bg-light');
        nav_element.classList.add('navbar-dark');
      } else {
        nav_element.classList.add('bg-light');
        nav_element.classList.remove('navbar-dark');
      }
    }
  };

  useEffect(() => {
    // Add a scroll event listener here to handle transparency based on scroll position
    const handleScroll = () => {
      const nav_element = document.querySelector('.navbar');
      if (window.scrollY < 56) {
        if (nav_element.classList.contains('bg-light')) {
          nav_element.classList.remove('bg-light');
          nav_element.classList.add('navbar-dark');
        }
      } else {
        if (nav_element.classList.contains('navbar-dark')) {
          nav_element.classList.remove('navbar-dark');
          nav_element.classList.add('bg-light');
        }
      }
    };

    // Add the scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //const openModal = () => setIsModalOpen(true);
  //const closeModal = () => setIsModalOpen(false);
/*
    return (
        <div className="nav-div">
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                <div className="container">
                    <a className="navbar-brand nav-link-selector0" href="#">reventt</a>
                    <button className="navbar-toggler nav-link-selector-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavbarToggle}>
                    <span className="navbar-toggler-icon nav-link-selector-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a className="nav-link active" href="#">Home</a>
                        <a className="nav-link active" href="#">Features</a>
                        <a className="nav-link active" href="#">Pricing</a>
                        <a className="nav-link active" href="#">Disabled</a>
                    </div>
                    </div>
                </div>
            </nav>
        </div>
    )*/
  /* works perfectly
  const handleCodeSuccess = async (codeResponse) => {
    try {
    const response = await axios.post('http://localhost:4000/api/exchange-code', { code: codeResponse.code });
    console.log(response);
    const userData = response.data.user;
    //setUser(userData);
    login(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    //closeModal();
  } catch (error) {
    console.error('Error exchanging code:', error);
  }
  };*/


const handleLoginFailure = (error) => {
  console.error('Login Failed', error);
};
  /*
  const googleLogin = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  onSuccess: handleLoginSuccess,
  onError: handleLoginFailure,
});
  */
  
  useEffect(() => {
  console.log('User Stripe Subscription ID:', user?.stripeSubscriptionId);
}, [user]);

  
  return (
    <div className="nav-div">
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">reventt</a>
          <button className="navbar-toggler nav-link-selector-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavbarToggle}>
            <span className="navbar-toggler-icon nav-link-selector-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <a className="nav-link active" href="/">Home</a>
              <a className="nav-link active" href="/dashboard">Dashboard</a>
              {user && user.stripeSubscriptionId ? (
                    <a className="nav-link active" href="/account-details">Account Details</a>
                ) : (
                    <a className="nav-link active" href="/pricing">Pricing</a>
                )}
        {user ? (
            <div>
              <a className="nav-link active welcomemessage" href="#">Welcome, {user.name.split(' ')[0]}</a>
              <a className="nav-link active logoutbutton" onClick={handleLogoutSuccess}>Log Out</a>
          </div>
        ) : (
                  //<a className="nav-link active loginbutton" onClick={openModal}>Log In</a>
                  <a href="/login" className="nav-link active loginbutton">Log In</a>

        )}
              {/*
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="google-button-container">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={handleLoginSuccess}
              onError={() => console.error('Login Error')}
                />
          </div>
        </Modal>*/}
              
              {/* below is the total working version
                      <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="google-button-container">
<CustomGoogleLoginButton onCodeSuccess={handleCodeSuccess} onLoginFailure={handleLoginFailure} />
          </div>
      </Modal>*/}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
