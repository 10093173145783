import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module5Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 5: Data Collections in Python</h1>

            <h2 className="lesson-h2">Understanding Data Collections</h2>
            <p className="lesson-p">Data collections in Python are powerful data structures that allow you to store and manipulate groups of data. In this module, we will explore three main types of collections: lists, tuples, and dictionaries. Understanding these collections is crucial as they enable the organization and efficient manipulation of data.</p>

<h3 className="lesson-h3">Lists</h3>
<p className="lesson-p">Lists in Python are ordered collections that can hold a variety of data types and are mutable, meaning they can be modified after creation. They are defined using square brackets `[]` with elements separated by commas. Lists are versatile and can be used to store a sequence of objects. They support indexing and slicing, which allows you to retrieve or update elements. Lists also support various methods like `append()` for adding items, `pop()` for removing items, and others for sorting and reversing the elements, making them highly flexible and useful in many programming scenarios.</p>
<p className="lesson-p">Indexing in lists starts at zero, meaning the first element is accessed with index 0. This zero-based indexing is a common convention in many programming languages. It allows for an intuitive counting system where the index corresponds to the number of elements from the start of the list. For example, in a list `[a, b, c]`, `a` is at index 0, `b` at index 1, and `c` at index 2. Understanding indexing is crucial for effectively navigating and manipulating list elements.</p>     
            <pre style={style.codeBlock}>
                {`# Creating a list
numbers = [1, 2, 3, 4, 5]
print(numbers)
# Output: [1, 2, 3, 4, 5]

# Accessing list elements
print(numbers[2])  # Accessing the third element
# Output: 3

# Modifying a list
numbers[2] = 10
print(numbers)
# Output: [1, 2, 10, 4, 5]`}
            </pre>

            <p className="lesson-p">Methods in Python are functions that are associated with objects. When it comes to lists, methods are built-in functions that you can call on list objects. These methods perform specific operations on the list, such as adding or removing elements, sorting, reversing, and more. Understanding these methods is crucial for effective manipulation and management of lists in Python programming. Each list method has a unique purpose and is called using the dot notation on the list object.</p>
            <h4>Common List Methods and Their Uses</h4>


            <ul>
    <li><code>append()</code>: Adds an item to the end of the list.</li>
    <li><code>insert()</code>: Inserts an item at a given position.</li>
    <li><code>pop()</code>: Removes and returns an item at a given index.</li>
    <li><code>remove()</code>: Removes the first occurrence of an item.</li>
    <li><code>clear()</code>: Removes all items from the list.</li>
    <li><code>index()</code>: Returns the index of the first occurrence of an item.</li>
    <li><code>count()</code>: Returns the number of occurrences of an item.</li>
    <li><code>sort()</code>: Sorts the items of the list in place.</li>
    <li><code>reverse()</code>: Reverses the elements of the list in place.</li>
    <li><code>extend()</code>: Extends the list by appending elements from another list.</li>
</ul>

<pre style={style.codeBlock}>
    {`# append() method
numbers = [1, 2, 3]
numbers.append(4)
print(numbers)  # Output: [1, 2, 3, 4]

# insert() method
numbers.insert(1, 1.5)
print(numbers)  # Output: [1, 1.5, 2, 3, 4]

# pop() method
removed_element = numbers.pop(2)
print(removed_element)  # Output: 2
print(numbers)  # Output: [1, 1.5, 3, 4]

# remove() method
numbers.remove(1.5)
print(numbers)  # Output: [1, 3, 4]

# clear() method
numbers.clear()
print(numbers)  # Output: []

# index() method
letters = ['a', 'b', 'c', 'd']
print(letters.index('c'))  # Output: 2

# count() method
letters.extend(['a', 'b'])
print(letters.count('a'))  # Output: 2

# sort() method
numbers = [3, 1, 4, 1, 5]
numbers.sort()
print(numbers)  # Output: [1, 1, 3, 4, 5]

# reverse() method
numbers.reverse()
print(numbers)  # Output: [5, 4, 3, 1, 1]

# extend() method
letters.extend(['e', 'f'])
print(letters)  # Output: ['a', 'b', 'c', 'd', 'a', 'b', 'e', 'f']`}
</pre>

<h3 className="lesson-h3">Tuples</h3>
<p className="lesson-p">Tuples in Python are similar to lists in that they are ordered collections of items, but unlike lists, they are immutable, meaning once a tuple is created, its content cannot be altered. This immutability makes tuples a safer choice for data that should not be modified. Tuples are often used for storing related pieces of information that are meant to be unchangeable throughout the program, like the coordinates of a point in 3D space. They are created using parentheses `()` or even without any brackets, with items separated by commas. Tuples support indexing and slicing, similar to lists, but do not have methods for adding or removing elements due to their immutable nature.</p>
<pre style={style.codeBlock}>
    {`# Creating a tuple
colors = ('red', 'green', 'blue')
print(colors)
# Output: ('red', 'green', 'blue')

# Accessing tuple elements
print(colors[1])
# Output: green

# Tuples are immutable
# colors[1] = 'yellow'  # This would result in an error`}
            </pre>
            <p className="lesson-p">The immutability of tuples restricts the use of methods that would otherwise modify the data. While lists provide methods to add, remove, or sort elements, tuples do not allow any operations that would change the tuple's content after its creation. This limitation is by design to ensure data integrity for collections of items that are not meant to be altered.</p>

<h4>Common Tuple Methods</h4>
<p className="lesson-p">Though limited in number, tuples do have some useful methods:</p>
<ul>
    <li><code>count():</code> Returns the number of times a specified value appears in the tuple.</li>
    <li><code>index():</code> Finds the first occurrence of a specified value and returns its index.</li>
</ul>
<pre style={style.codeBlock}>
    {`# Example tuple
numbers = (1, 2, 3, 4, 3, 2, 1)

# Using count()
count_of_2 = numbers.count(2)
print("Number 2 appears", count_of_2, "times")
# Output: Number 2 appears 2 times

# Using index()
index_of_4 = numbers.index(4)
print("Index of number 4:", index_of_4)
# Output: Index of number 4: 3`}
</pre>


<h3 className="lesson-h3">Sets</h3>
<p className="lesson-p">Sets in Python are an unordered collection of unique elements. They are particularly useful when the order of elements is not important, but uniqueness is key. Being mutable, sets allow elements to be added or removed; however, they only store non-duplicate items, automatically filtering out any repetitions. Sets are created using curly braces or the `set()` function. They are ideal for operations like union, intersection, and difference, which are common in mathematical set theory. Sets also provide an efficient way to test for membership, as they are implemented using hash tables, allowing for quick lookup times. However, since sets are unordered, they do not support indexing or slicing like lists or tuples.</p>
<pre style={style.codeBlock}>
    {`# Creating a set
fruits = {'apple', 'banana', 'cherry'}
print(fruits)
# Output: {'apple', 'banana', 'cherry'} (order may vary)

# Adding an element to a set
fruits.add('orange')
print(fruits)
# Output: {'apple', 'banana', 'cherry', 'orange'} (order may vary)

# Removing an element
fruits.remove('banana')
print(fruits)
# Output: {'apple', 'cherry', 'orange'} (order may vary)

# Set operations
a = {1, 2, 3}
b = {3, 4, 5}
print(a.union(b))  # Union of sets
# Output: {1, 2, 3, 4, 5}
print(a.intersection(b))  # Intersection of sets
# Output: {3}`}
            </pre>
            
                        <h4>Common Set Methods</h4>
            <ul>
                <li><code>add(element)</code>: Adds an element to the set.</li>
                <li><code>remove(element)</code>: Removes an element from the set. Raises an error if not present.</li>
                <li><code>discard(element)</code>: Removes an element if present, otherwise does nothing.</li>
                <li><code>union(other_set)</code>: Returns a new set containing all elements from both sets.</li>
                <li><code>intersection(other_set)</code>: Returns a new set with elements common to both sets.</li>
                <li><code>difference(other_set)</code>: Returns a new set with elements in the set but not in the other set.</li>
                <li><code>update(other_set)</code>: Adds elements from another set to the set.</li>
                <li><code>pop()</code>: Removes and returns an arbitrary set element. Raises an error if the set is empty.</li>
                <li><code>clear()</code>: Removes all elements from the set.</li>
            </ul>

            <pre style={style.codeBlock}>
                {`# Example: Using set methods

# add(element)
fruits = {'apple', 'banana', 'cherry'}
fruits.add('mango')
print(fruits)
# Output: {'apple', 'banana', 'cherry', 'mango'}

# remove(element)
fruits.remove('banana')
print(fruits)
# Output: {'apple', 'cherry', 'mango'}

# discard(element)
fruits.discard('pear')  # 'pear' is not in the set
print(fruits)
# Output: {'apple', 'cherry', 'mango'}

# union(other_set)
print(fruits.union({'grape', 'pear'}))
# Output: {'apple', 'cherry', 'mango', 'grape', 'pear'}

# intersection(other_set)
print(fruits.intersection({'grape', 'apple'}))
# Output: {'apple'}

# difference(other_set)
print(fruits.difference({'apple'}))
# Output: {'cherry', 'mango'}

# update(other_set)
fruits.update({'kiwi', 'banana'})
print(fruits)
# Output: {'kiwi', 'apple', 'cherry', 'banana', 'mango'}

# pop()
popped = fruits.pop()
print('Popped element:', popped)
print('Updated set:', fruits)
# Output: Popped element: (some element from the set)
# Updated set: (remaining elements in the set)

# clear()
fruits.clear()
print(fruits)
# Output: set()`}
            </pre>


            <h3 className="lesson-h3">Dictionaries</h3>
            <p className="lesson-p">Dictionaries in Python are powerful data structures used for mapping unique keys to values. Unlike lists and tuples, dictionaries store data in a key-value pair, allowing for faster access via keys rather than indices. This makes them ideal for situations where items are named or labeled, such as storing properties of an object. The keys in a dictionary are unique and can be of any immutable type, typically strings or numbers. Dictionaries are flexible as they can store a wide variety of data types as values and are mutable, meaning you can add, remove, or modify their content.</p>

            <p>Dictionaries are particularly useful for data retrieval where identifying an element with a name or key is more intuitive than using an index. This characteristic makes dictionaries an essential tool for handling complex data structures in Python programming.</p>
            <pre style={style.codeBlock}>
                {`# Creating a dictionary
student = {'name': 'Alice', 'age': 22, 'course': 'Python'}
print(student)
# Output: {'name': 'Alice', 'age': 22, 'course': 'Python'}

# Accessing dictionary values
print(student['name'])
# Output: Alice

# Modifying a dictionary
student['age'] = 23
print(student)
# Output: {'name': 'Alice', 'age': 23, 'course': 'Python'}`}
            </pre>

                        <h4>Common Dictionary Methods</h4>
            <ul>
                <li><code>get(key)</code>: Returns the value for a key, or None if the key is not found.</li>
                <li><code>keys()</code>: Returns a view object displaying a list of all the keys in the dictionary.</li>
                <li><code>values()</code>: Returns a view object displaying a list of all the values in the dictionary.</li>
                <li><code>items()</code>: Returns a view of all key-value pairs in the dictionary.</li>
                <li><code>update(other_dict)</code>: Updates the dictionary with key-value pairs from another dictionary.</li>
                <li><code>pop(key)</code>: Removes the element with the specified key and returns its value.</li>
                <li><code>clear()</code>: Removes all items from the dictionary.</li>
            </ul>

            <pre style={style.codeBlock}>
                {`# Example: Using dictionary methods
info = {'name': 'Alice', 'age': 22}

# Using get()
print(info.get('name'))  # Output: Alice

# Using keys()
print(info.keys())  # Output: dict_keys(['name', 'age'])

# Using values()
print(info.values())  # Output: dict_values(['Alice', 22])

# Using items()
print(info.items())  # Output: dict_items([('name', 'Alice'), ('age', 22)])

# Using update()
info.update({'course': 'Python'})
print(info)  # Output: {'name': 'Alice', 'age': 22, 'course': 'Python'}

# Using pop()
age = info.pop('age')
print(age)  # Output: 22
print(info)  # Output: {'name': 'Alice', 'course': 'Python'}

# Using clear()
info.clear()
print(info)  # Output: {}`}
            </pre>

                        <h2 className="lesson-h2">Using the len() Function with Data Collections</h2>
            <p className="lesson-p">The <code>len()</code> function is used to get the number of items in a data collection. It can be applied to lists, tuples, sets, and dictionaries in Python. This function is incredibly useful for quickly determining the size of a collection, which is a common requirement in various programming scenarios.</p>

            <pre style={style.codeBlock}>
                {`# Using len() with a list
numbers_list = [1, 2, 3, 4, 5]
print(len(numbers_list))  # Output: 5

# Using len() with a tuple
colors_tuple = ('red', 'green', 'blue')
print(len(colors_tuple))  # Output: 3

# Using len() with a set
fruits_set = {'apple', 'banana', 'cherry'}
print(len(fruits_set))  # Output: 3

# Using len() with a dictionary
student_dict = {'name': 'Alice', 'age': 22, 'course': 'Python'}
print(len(student_dict))  # Output: 3`}
            </pre>

            <p className="lesson-p">This segment shows how the <code>len()</code> function can be universally applied across different data collections to measure their size. It's an essential tool for managing and processing collections of data in Python.</p>

<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Test your understanding of Python data collections with these exercises.</p>
<ol>
    <li>
        Create a list of your three favorite fruits and use the <code>append()</code> method to add another fruit to the list. Print the updated list.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
fruits = ['apple', 'banana', 'cherry']
fruits.append('orange')
print(fruits)
# Output: ['apple', 'banana', 'cherry', 'orange']`}
        </pre>}
    </li>
    <li>
        Given a tuple <code>('a', 'b', 'c')</code>, write a Python script to print the index of 'b' in the tuple.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
letters = ('a', 'b', 'c')
index_of_b = letters.index('b')
print(index_of_b)
# Output: 1`}
        </pre>}
    </li>
    <li>
        Create a set containing numbers from 1 to 5. Remove number 3 from the set and then print the updated set.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
numbers_set = {1, 2, 3, 4, 5}
numbers_set.remove(3)
print(numbers_set)
# Output: {1, 2, 4, 5}`}
        </pre>}
    </li>
    <li>
        Create a dictionary for a vehicle with keys 'brand', 'model', and 'year', and appropriate values for each. Print the value of 'model' using the <code>get()</code> method.
        <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution4 && <pre style={style.codeBlock}>
            {`# Solution
vehicle = {'brand': 'Toyota', 'model': 'Corolla', 'year': 2018}
model = vehicle.get('model')
print(model)
# Output: Corolla`}
        </pre>}
    </li>
    <li>
        Given a dictionary <code>{"{'name': 'Alice', 'age': 25, 'city': 'New York'}"}</code>, update the 'age' to 26 and then print the entire updated dictionary.
        <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution5 && <pre style={style.codeBlock}>
            {`# Solution
person = {"name": "Alice", "age": 25, "city": "New York"}
person["age"] = 26
print(person)
# Output: {"name": "Alice", "age": 26, "city": "New York"}`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 5 and Preview of Module 6</h2>
            <p className="lesson-p">This module provided a comprehensive overview of Python's fundamental data collections: lists, tuples, and dictionaries. Understanding these structures is essential for efficient data storage and manipulation in Python programming.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module5Lesson;
