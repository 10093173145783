import React from 'react';
import './Privacy.css';

const Privacy = () => {

return (
    <div className="privacy-page">

        <div className="gradient-privacy-page">

        <div className="privacy-page-content">
        <h1>PRIVACY POLICY</h1>
        <p>Last updated February 10, 2024</p><br />
        <p>This privacy notice for Reventt, LLC, describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
            Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</p><br />
        
        <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3>Personal information you disclose to us</h3>
        <p>In Short: We collect personal information that you provide to us.<br/>
We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.<br/>
Sensitive Information. We do not process sensitive information.<br/>
All personal information that you provide to us must be true, complete, and accurate,
            and you must notify us of any changes to such personal information.</p><br />
        <h3>Information automatically collected</h3>
        <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.<br/>
            Like many businesses, we also collect information through cookies and similar technologies.</p>
        
        <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
        <p>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p><br />
        
        <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
        <p>We may need to share your personal information in the following situations:<br />
            <ul>
                <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            </ul>
        </p>

        <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</p><br />
        
        <h2>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
        <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.<br />We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p><br />
        
        <h2>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
        <p>Our servers are located in the United States. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other countries.<br/>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
        </p>
        
        <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p><br />
        
        <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services.</p><br />
        
        <h2>9. ACCOUNT INFORMATION</h2>
        <p>If you would at any time like to review or change the information in your account or terminate your account, you can:<br/>
            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
        
        <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p>If you have questions or comments about this notice, you may contact us at:<br />reventtglobal@gmail.com</p>
            </div>
            </div>
    </div>
);

};

export default Privacy;
