import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module6Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 6: Strings and Their Manipulations</h1>

            <h2 className="lesson-h2">Introduction to Strings in Python</h2>
            <p className="lesson-p">Strings are one of the most common data types in Python and are used to represent text. A string in Python is an immutable sequence of characters. Understanding how to effectively manipulate strings is crucial for data processing, text analysis, and more.</p>

            <h3 className="lesson-h3">Creating and Accessing Strings</h3>
            <p className="lesson-p">
    Strings in Python are sequences of characters that can be created by enclosing characters in single quotes 'Hello World', double quotes "Hello World", or triple quotes for multi-line strings. This flexibility allows for easy creation of strings, whether they are simple words, sentences, or even paragraphs. Once a string is created, you can access individual characters using indexing, with the first character at index 0. You can also extract a range of characters using slicing, which specifies a start and end point within the string. This capability to access and slice strings is powerful, enabling you to extract and manipulate specific parts of the string as needed.
</p>
            <pre style={style.codeBlock}>
                {`# Creating a string
greeting = "Hello, World!"
print(greeting)
# Output: Hello, World!

# Accessing string characters
first_char = greeting[0]
print(first_char)
# Output: H

# Slicing a string
slice_greeting = greeting[0:5]
print(slice_greeting)
# Output: Hello

# Extracting a substring using slicing
phrase = "Python Programming"
programming = phrase[7:]  # Start at index 7 and go to the end
print(programming)
# Output: Programming

# Creating a string
phrase = "Python Programming"
# Slicing the string from the beginning up to index 6
python = phrase[:6]
print(python)
# Output: Python`}
</pre>

            <h3 className="lesson-h3">String Methods</h3>
            <p className="lesson-p">Python provides a variety of built-in methods for string manipulation. These methods do not modify the original string but return a new string with the desired modifications.</p>
            <h4>Common String Methods</h4>
            <ul>
                <li><code>lower()</code>: Converts all characters in the string to lowercase.</li>
                <li><code>upper()</code>: Converts all characters in the string to uppercase.</li>
                <li><code>strip()</code>: Removes whitespace from the beginning and end of the string.</li>
                <li><code>split()</code>: Splits the string into a list of substrings based on a separator.</li>
                <li><code>replace(old, new)</code>: Replaces occurrences of a substring with another substring.</li>
            </ul>
            <pre style={style.codeBlock}>
                {`# Example: Using string methods
text = " Hello, Python! "
print(text.lower())  # Output: hello, python!
print(text.upper())  # Output: HELLO, PYTHON!
print(text.strip())  # Output: Hello, Python!
print(text.split(','))  # Output: [' Hello', ' Python!']
print(text.replace('Python', 'World'))  # Output: Hello, World!`}
            </pre>

            <h3 className="lesson-h3">String Formatting</h3>
            <p className="lesson-p">Python offers several ways to format strings for displaying variables and expressions in a formatted manner. The most common methods are using f-strings and the `format()` method.</p>
            <pre style={style.codeBlock}>
                {`# Using f-strings
name = "Alice"
age = 25
greeting = f"Hello, {name}. You are {age} years old."
print(greeting)
# Output: Hello, Alice. You are 25 years old.

# Using format() method
welcome_message = "Welcome, {}. Today is {}.".format(name, "Monday")
print(welcome_message)
# Output: Welcome, Alice. Today is Monday.`}
            </pre>

            <h2 className="lesson-h2">Practice Exercises</h2>
            <p className="lesson-p">Try these exercises to practice string manipulation in Python.</p>
            <ol>
                <li>
                    Create a string with your name and print it in reverse order.
                    <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution1 && <pre style={style.codeBlock}>
                        {`# Solution
name = "Alice"
reversed_name = name[::-1]
print(reversed_name)
# Output: ecilA`}
                    </pre>}
                </li>
                <li>
                    Given the string "Python Programming", use slicing to print only "Programming".
                    <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution2 && <pre style={style.codeBlock}>
                        {`# Solution
text = "Python Programming"
programming = text[7:]
print(programming)
# Output: Programming`}
                    </pre>}
                </li>
                <li>
                    Create a string "Data Science" and convert it to uppercase using a string method.
                    <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution3 && <pre style={style.codeBlock}>
                        {`# Solution
text = "Data Science"
uppercase_text = text.upper()
print(uppercase_text)
# Output: DATA SCIENCE`}
                    </pre>}
                </li>
            </ol>

            <h2 className="lesson-h2">Recap of Module 6 and Preview of Module 7</h2>
            <p className="lesson-p">This module covered essential aspects of string manipulation in Python, including creating, accessing, and manipulating strings using various methods and formatting techniques. Mastering these skills is vital for handling textual data effectively in Python programming.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module6Lesson;
