import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module9Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 9: Advanced Data Collections in Python</h1>

            <h2 className="lesson-h2">Exploring Advanced Collections</h2>
            <p className="lesson-p">This module goes beyond basic lists, tuples, and dictionaries to explore more complex data structures like sets, frozensets, namedtuples, and more. These advanced collections provide additional functionality and efficiency for specific use cases in Python.</p>

<h3 className="lesson-h3">Sets and Frozensets</h3>
<p className="lesson-p">Sets are unordered collections of unique elements, ideal for membership testing, deduplication, and set operations like union and intersection. Frozensets are similar but immutable, meaning once created, their contents cannot be changed. This immutability makes frozensets hashable and suitable for use as dictionary keys or set elements. Both sets and frozensets are highly optimized for performance, especially for large datasets.</p>
            <pre style={style.codeBlock}>
                {`# Working with sets
my_set = {1, 2, 3}
print(my_set)
# Output: {1, 2, 3}

# Working with frozensets
my_frozenset = frozenset([4, 5, 6])
print(my_frozenset)
# Output: frozenset({4, 5, 6})`}
            </pre>

            <h3 className="lesson-h3">Pros and Cons of Sets and Frozensets</h3>
<p>
   <strong>Pros:</strong>
   <ul>
      <li>Efficient for membership tests and eliminating duplicates.</li>
      <li>Supports mathematical set operations like union, intersection.</li>
      <li>Frozensets are hashable, can be used as dictionary keys.</li>
   </ul>
   <strong>Cons:</strong>
   <ul>
      <li>Unordered, thus not suitable for tasks requiring order preservation.</li>
      <li>Frozensets are immutable, so they can't be modified after creation.</li>
   </ul>
</p>


<h3 className="lesson-h3">Named Tuples</h3>
<p className="lesson-p">Named tuples, from Python's `collections` module, extend the functionality of tuples. They allow you to access elements by name, improving readability and self-documenting your code. Named tuples are lightweight, require no more memory than regular tuples, and their fields are immutable like standard tuples. They are particularly useful for data that should not change once assigned and for simplifying code that deals with complex data structures.</p>
            <pre style={style.codeBlock}>
                {`from collections import namedtuple

# Creating a named tuple
Point = namedtuple('Point', ['x', 'y'])
p = Point(10, 20)
print(p.x, p.y)
# Output: 10 20`}
            </pre>

            <h3>Pros and Cons of Named Tuples</h3>
<p>
   <strong>Pros:</strong>
   <ul>
      <li>Memory-efficient and more readable than regular tuples.</li>
      <li>Immutable, ensuring data integrity.</li>
      <li>Can access elements by name for clearer code.</li>
   </ul>
   <strong>Cons:</strong>
   <ul>
      <li>Less flexible than dictionaries due to immutability.</li>
      <li>Not suitable for data that needs to be updated frequently.</li>
   </ul>
</p>


<h3 className="lesson-h3">Deques</h3>
<p className="lesson-p">Deques (double-ended queues) offer efficient operations for appending and popping elements from both ends. Unlike lists, deques are optimized for these operations, providing near-constant time complexity. They are ideal for tasks like maintaining a sliding window of elements or implementing queues and stacks. Deques also support thread-safe, memory-efficient appends and pops from either side of the deque with approximately the same performance in either direction.</p>
            <pre style={style.codeBlock}>
                {`from collections import deque

# Working with deques
d = deque('ghi')
d.append('j')
d.appendleft('f')
print(d)
# Output: deque(['f', 'g', 'h', 'i', 'j'])`}
            </pre>

            <h3>Pros and Cons of Deques</h3>
<p>
   <strong>Pros:</strong>
   <ul>
      <li>Fast appends and pops from both ends.</li>
      <li>More memory-efficient for large datasets than lists.</li>
      <li>Can be used as both stacks and queues.</li>
   </ul>
   <strong>Cons:</strong>
   <ul>
      <li>Slower lookups compared to lists for middle elements.</li>
      <li>Not as intuitive for beginners as standard lists.</li>
   </ul>
</p>


<h3 className="lesson-h3">Counters</h3>
<p className="lesson-p">Counter is a dictionary subclass in Python's `collections` module, designed for counting hashable objects. It's an effective tool for tallying elements and simplifies tasks that would normally require manual accumulation of counts. Counters provide methods for common operations like finding the most common elements and can be used to elegantly solve problems related to data counting and frequency analysis. They also support arithmetic and set operations for aggregating results.</p>
            <pre style={style.codeBlock}>
                {`from collections import Counter

# Using Counter
c = Counter('hello world')
print(c)
# Output: Counter({'l': 3, 'o': 2, 'h': 1, 'e': 1, ' ': 1, 'w': 1, 'r': 1, 'd': 1})`}
            </pre>

            <h3>Pros and Cons of Counters</h3>
<p>
   <strong>Pros:</strong>
   <ul>
      <li>Automatically handles counting and frequency analysis.</li>
      <li>Supports arithmetic and set operations for count manipulation.</li>
      <li>Intuitive and easy to use for counting tasks.</li>
   </ul>
   <strong>Cons:</strong>
   <ul>
      <li>Less efficient for very large datasets compared to specialized data structures.</li>
      <li>Overhead of a dictionary subclass may not be necessary for simple counts.</li>
   </ul>
            </p>
            
                        <h3>Common Methods for Each Collection Type</h3>
            <p>Each collection type has a set of common methods that are frequently used for various operations. Here are some of the most common methods:</p>
            
            <h4>Sets and Frozensets</h4>
            <ul>
                <li><code>add(element)</code>: Adds an element to a set.</li>
                <li><code>remove(element)</code>: Removes an element from a set. Raises an error if the element is not present.</li>
                <li><code>union(other)</code>, <code>intersection(other)</code>, <code>difference(other)</code>: Set operations.</li>
            </ul>

            <h4>Named Tuples</h4>
            <ul>
                <li>Accessing elements by name: <code>namedtuple_instance.field_name</code></li>
                <li>Conversion to a regular tuple: <code>tuple(namedtuple_instance)</code></li>
            </ul>

            <h4>Deques</h4>
            <ul>
                <li><code>append(element)</code> and <code>appendleft(element)</code>: Add elements to either end.</li>
                <li><code>pop()</code> and <code>popleft()</code>: Remove elements from either end.</li>
                <li><code>extend(iterable)</code> and <code>extendleft(iterable)</code>: Add multiple elements at once.</li>
            </ul>

            <h4>Counters</h4>
            <ul>
                <li><code>most_common(n)</code>: Returns a list of the n most common elements and their counts.</li>
                <li><code>update(iterable)</code>: Adds counts from another iterable.</li>
                <li><code>subtract(iterable)</code>: Subtracts count, but keeps only positive counts.</li>
            </ul>


            <h3 className="lesson-h3">Converting Between Collection Types</h3>
<p className="lesson-p">Understanding how to convert between different collection types is crucial for efficient data manipulation in Python. This section covers conversions for sets, frozensets, namedtuples, deques, and counters.</p>

<pre style={style.codeBlock}>
    {`# Converting a set to a frozenset
my_set = {1, 2, 3}
my_frozenset = frozenset(my_set)
print(my_frozenset)
# Output: frozenset({1, 2, 3})

# Converting a frozenset to a set
new_set = set(my_frozenset)
print(new_set)
# Output: {1, 2, 3}

# Converting a list to a deque
my_list = [1, 2, 3]
my_deque = deque(my_list)
print(my_deque)
# Output: deque([1, 2, 3])

# Converting a deque to a list
new_list = list(my_deque)
print(new_list)
# Output: [1, 2, 3]

# Converting a Counter to a dictionary
my_counter = Counter('hello world')
my_dict = dict(my_counter)
print(my_dict)
# Output: {'h': 1, 'e': 1, 'l': 3, 'o': 2, ' ': 1, 'w': 1, 'r': 1, 'd': 1}

# Converting a dictionary to a Counter
new_counter = Counter(my_dict)
print(new_counter)
# Output: Counter({'l': 3, 'o': 2, 'h': 1, 'e': 1, ' ': 1, 'w': 1, 'r': 1, 'd': 1})

# Converting a tuple to a named tuple
Color = namedtuple('Color', ['red', 'green', 'blue'])
my_tuple = (255, 200, 100)
color = Color._make(my_tuple)
print(color)
# Output: Color(red=255, green=200, blue=100)

# Converting a named tuple to a tuple
new_tuple = tuple(color)
print(new_tuple)
# Output: (255, 200, 100)`}
</pre>



<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Apply your understanding of advanced data collections and conversions with these simpler exercises.</p>
<ol>
    <li>
        Convert a list of numbers into a set and print the result.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
numbers_list = [1, 2, 2, 3, 4]
numbers_set = set(numbers_list)
print(numbers_set)
# Output: {1, 2, 3, 4}`}
        </pre>}
    </li>
    <li>
        Convert the given set into a frozenset.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
numbers_set = {5, 6, 7}
numbers_frozenset = frozenset(numbers_set)
print(numbers_frozenset)
# Output: frozenset({5, 6, 7})`}
        </pre>}
    </li>
    <li>
        Create a named tuple for a 'Book' with fields 'title' and 'author', then instantiate it with any book and author.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
from collections import namedtuple
Book = namedtuple('Book', ['title', 'author'])
my_book = Book('1984', 'George Orwell')
print(my_book)
# Output: Book(title='1984', author='George Orwell')`}
        </pre>}
    </li>
    <li>
        Convert the following list into a deque and use `appendleft()` to add an element to the beginning.
        <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution4 && <pre style={style.codeBlock}>
            {`# Solution
from collections import deque
my_list = [8, 9, 10]
my_deque = deque(my_list)
my_deque.appendleft(7)
print(my_deque)
# Output: deque([7, 8, 9, 10])`}
        </pre>}
    </li>
    <li>
        Create a Counter from a string and print the two most common characters.
        <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution5 && <pre style={style.codeBlock}>
            {`# Solution
from collections import Counter
my_string = 'abracadabra'
counter = Counter(my_string)
most_common_chars = counter.most_common(2)
print(most_common_chars)
# Output: [('a', 5), ('b', 2)]`}
        </pre>}
    </li>
</ol>




            <h2 className="lesson-h2">Recap of Module 9 and Preview of Module 10</h2>
            <p className="lesson-p">Module 9 provided an in-depth look at advanced data collections in Python. These collections enhance the ability to handle complex data structures and perform sophisticated operations with greater efficiency.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module9Lesson;
