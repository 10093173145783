import React from 'react'
import createGlobe from "cobe";
import { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from './AuthContext';
import './Globe.css'


export default function Globe() {
  const { user } = useContext(AuthContext);
  const canvasRef = useRef();

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0.25,
      dark: -10,
      diffuse: 0,
      mapSamples: 25000,
      mapBrightness: 2,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      opacity: 0.5,
      markers: [
      ],
      onRender: (state) => {
        state.phi = phi;
        phi += 0.005;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);

  /* shifting color button 
  const [mouseX, setMouseX] = useState(50);
  const [mouseY, setMouseY] = useState(50);

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = 100 - ((e.clientX - rect.left) * 100 / e.target.clientWidth);
    const y = 100 - ((e.clientY - rect.top) * 100 / e.target.clientHeight);
    setMouseX(x);
    setMouseY(y);
  };

  const buttonStyle = {
    '--mouse-x': mouseX,
    '--mouse-y': mouseY,
  };*/

  return (
    <div className="globe-container">
      <div className="globe-header-text">
        <h1>Empowering Aspiring Programmers Globally, Regardless of Their Location</h1><br />
        <p>Unlock your programming potential – learn from anywhere, anytime. Your journey to coding excellence knows no bounds.</p>
        {/*<button style={buttonStyle} onMouseMove={handleMouseMove}>Buy Reventt</button>*/}
          <button className="globe-header-button">
              {user && user.stripeSubscriptionId ? (
                  <span className="globe-header-button-span"><a className="globe-header-button-text" href="/dashboard">Dashboard</a></span>
              ) : (
                  <span className="globe-header-button-span"><a className="globe-header-button-text" href="/pricing">Buy Now</a></span>
              )}
          </button>
        </div>
      <div className="globe">
        <canvas
          ref={canvasRef}
          style={{ width: 600, height: 600, maxWidth: "100%", aspectRatio: 1 }}
        />
      </div>
    </div>
  );
}
