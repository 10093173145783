import './Comparison.css';

const Comparison = () => {
    return (
        <div className="comparison-container">

            <div className="comparison-text">
                <h1>What Sets Us Apart</h1>
            </div>
            
            <div className="comparison-table">
            <table>
                <thead>
                <tr>
                    <th className="non-custom-color"></th>
                    <th className="non-custom-color">Youtube</th>
                    <th className="non-custom-color">Coding Bootcamps</th>
                    <th className="custom-color">reventt</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Affordable</td>
                    <td><span className="checkmark">✔</span></td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                <tr>
                    <td>In-Depth Coding Curriculum</td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                <tr>
                    <td>Hands-On Coding Problems</td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                <tr>
                    <td>Comprehensive Quizzes After Each Module</td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                <tr>
                    <td>Score-Based Quiz Feedback</td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                <tr>
                    <td>Best Value</td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="cross">✘</span></td>
                    <td><span className="checkmark">✔</span></td>
                </tr>
                </tbody>
            </table>
            </div>
            
        </div>


    );
}

export default Comparison;
