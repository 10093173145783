/*import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Adjust the import path as necessary

const ProtectedRoute = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();

    return user ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};


export default ProtectedRoute;
*/

import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();

    // Check if the user is logged in
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Check if the user has an active subscription
    if (!user.stripeSubscriptionId) {
        return <Navigate to="/pricing" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
