import React, { useState } from 'react';
import './Footer.css';
import Modal from './Modal';

const Footer = () => {

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
/*
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="footer-col">
                    <h4>company</h4>
                    <ul>
                        <li><a className="footer-link" href="#">about us</a></li>
                        <li><a className="footer-link" href="#">our services</a></li>
                        <li><a className="footer-link" href="#">privacy policy</a></li>
                        <li><a className="footer-link" href="#">affiliate program</a></li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>get help</h4>
                    <ul>
                        <li><a className="footer-link" href="#">FAQ</a></li>
                        <li><a className="footer-link" href="#">shipping</a></li>
                        <li><a className="footer-link" href="#">returns</a></li>
                        <li><a className="footer-link" href="#">order status</a></li>
                        <li><a className="footer-link" href="#">payment options</a></li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>online shop</h4>
                    <ul>
                        <li><a className="footer-link" href="#">watch</a></li>
                        <li><a className="footer-link" href="#">bag</a></li>
                        <li><a className="footer-link" href="#">shoes</a></li>
                        <li><a className="footer-link" href="#">dress</a></li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>follow us</h4>
                    <div className="social-links">
                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>*/
        
<footer className="footer">
    <a className="footer-link" href="/privacy">Privacy Policy</a>
    <a className="footer-link" href="/terms-and-conditions">Terms and Conditions</a>
    <a className="footer-link" href="/articles">Programming Articles</a>
    <a className="footer-link contact-us-link" onClick={openModal}>Contact Us</a>
    <div className="footer-social-links">
        <a target="_blank" className="footer-social-link" href="https://www.facebook.com/reventtglobal"><i className="fab fa-facebook-f"></i></a>
        <a target="_blank" className="footer-social-link" href="https://twitter.com/ReventtGlobal"><i className="fab fa-x-twitter"></i></a>
        <a target="_blank" className="footer-social-link" href="https://www.instagram.com/reventtglobal"><i className="fab fa-instagram"></i></a>
        <a target="_blank" className="footer-social-link" href="https://www.linkedin.com/company/reventt"><i className="fab fa-linkedin-in"></i></a>
        <a target="_blank" className="footer-social-link" href="https://www.tiktok.com/@reventtglobal"><i className="fab fa-tiktok"></i></a>
    </div>
    <div className="footer-company-name">
        © 2024 Reventt, LLC
    </div>
    {isModalOpen && <Modal onClose={closeModal} />}
</footer>
    );
}

export default Footer;
