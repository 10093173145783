// Login.js (New Login Page Component)
import React, { useState, useEffect, useContext } from 'react';
//import { Gradient } from 'whatamesh';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { AuthContext } from './AuthContext';
import CustomGoogleLoginButton from './CustomGoogleLoginButton';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const { user, login } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
/*
    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient("#gradient-canvas-login");
    });*/

    useEffect(() => {
        if (user) {
            const from = location.state?.from?.pathname || "/";
            navigate(from);
        }
    }, [user, navigate, location.state]);

  const handleCodeSuccess = async (codeResponse) => {
    try {
      const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';
      const response = await axios.post(`${BASE_URL}/api/exchange-code`, { code: codeResponse.code });
      //const response = await axios.post('http://localhost:4000/api/exchange-code', { code: codeResponse.code });
      console.log(response);
      const userData = response.data.user;

      // Update the global auth state
        login(userData);

      // Store user data in localStorage
      localStorage.setItem('user', JSON.stringify(userData));

        // Check the navigation state for the action
        const action = location.state?.action;
        const from = location.state?.from?.pathname || "/";

        if (action === 'subscribe') {
            // Redirect to pricing for subscription process
            navigate('/pricing', { state: { action: 'subscribe' } });
        } else {
            // Redirect to the intended page or home page if no specific page was intended
            navigate(from);
        }
    } catch (error) {
      console.error('Error exchanging code:', error);
      // Handle error
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Login Failed', error);
    // Handle login failure
  };
    
    useEffect(() => {
    if (user) {
        const from = location.state?.from?.pathname || "/";
        navigate(from);
    }
    }, [user, navigate, location.state]);
  
  


  return (
        <div className="login-container">
      <Helmet>
        <title>Login or Sign Up | Reventt</title>
        <meta
          name="description"
          content="Log in or sign up to begin learning to code with Reventt. Start your coding journey now!"
        />
      </Helmet>

      {/*<canvas id="gradient-canvas-login" className="canvas-style"></canvas>*/}
          <div className="login-card">
            <div className="login-card-content">
                <h1 className="login-text-header">Log in or Sign Up</h1>
                <CustomGoogleLoginButton onCodeSuccess={handleCodeSuccess} onLoginFailure={handleLoginFailure} />
            </div>
            </div>
        </div>
  );
};

export default Login;
