import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom'; // If using React Router
import './List.css';

const modulesData = [
    {
        title: 'Introduction to Python',
        description: 'Essential for understanding the basics and setting up the environment.'
    },
    {
        title: 'Basic Concepts and Operations',
        description: 'Fundamental building blocks of Python programming, including understanding different data types and basic operations.'
    },
    {
        title: 'Control Flow',
        description: 'Critical for writing programs that can make decisions (conditional statements) and perform repetitive tasks (loops).'
    },
    {
        title: 'Functions',
        description: 'Understanding functions is key for writing organized and reusable code.'
    },
    {
        title: 'Data Collections',
        description: 'Lists, tuples, and dictionaries are fundamental data structures in Python, used to store and manipulate data.'
    },
    {
        title: 'Strings and Their Manipulations',
        description: 'Strings are a fundamental data type in Python, and knowing how to manipulate them is crucial.'
    },
    {
        title: 'Basic File Handling',
        description: 'Reading from and writing to files is a common requirement in many Python programs.'
    },
    {
        title: 'Error and Exception Handling',
        description: 'Essential for writing robust programs that can handle unexpected errors.'
    },
    {
        title: 'Advanced Data Collections',
        description: 'Expanding knowledge on data structures, which is important for more complex data manipulation.'
    },
    {
        title: 'Modules and Packages',
        description: 'Understanding modules and packages is crucial for leveraging existing code and organizing larger projects.'
    },
    {
        title: 'Intermediate File Handling',
        description: 'Introduces handling more complex file formats which are common in real-world applications.'
    },
    {
        title: 'Object-Oriented Programming (OOP)',
        description: 'A key programming paradigm in Python that helps in managing larger codebases more effectively.'
    },
    {
        title: 'Functional Programming Concepts',
        description: 'Provides an alternative approach to problem-solving and complements OOP.'
    },
    {
        title: 'Python Standard Library Exploration',
        description: 'The vast standard library is one of Python’s greatest strengths, offering a wide range of functionalities.'
    },
    {
        title: 'Algorithms and Data Structures',
        description: 'Fundamental for understanding how to store data efficiently and solve problems effectively.'
    }
];


function List() {
    const { user } = useContext(AuthContext);
    const [quizzes, setQuizzes] = useState([]);
    const navigate = useNavigate(); // If using React Router

    const [quizScores, setQuizScores] = useState({});


    //new stuff
    const [activeModule, setActiveModule] = useState(null);
    const toggleModule = (moduleTitle) => {
        setActiveModule(activeModule === moduleTitle ? null : moduleTitle);
    };
    //end of new stuff
    
/*
    useEffect(() => {
        if (!token) {
            console.error("No token found. User not logged in.");
            return;
        }
        axios.get('http://localhost:4000/api/quizzes')
            .then(response => setQuizzes(response.data))
            .catch(error => console.error('Error fetching quizzes:', error));
    }, [token]);*/

    useEffect(() => {
  if (!user) {
    console.error("User not logged in.");
    return;
  }

    //axios.get('http://localhost:4000/api/quizzes')
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/quizzes`)
    .then(response => {
      // Add a module reference to each quiz
      const quizzesWithModule = response.data.map(quiz => {
        return {
          ...quiz,
          module: modulesData[quiz.quizNumber - 1] // Assuming quizNumber starts from 1
        };
      });
      setQuizzes(quizzesWithModule);
    })
    .catch(error => console.error('Error fetching quizzes:', error));
}, [user]);


    useEffect(() => {
        if (user) {
            //axios.get(`http://localhost:4000/api/user/${user._id}/quiz-scores`)
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/${user._id}/quiz-scores`)
                .then(response => {
                    const scores = {};
                    response.data.forEach(qs => {
                        scores[qs.quizId._id] = qs.bestScore;
                    });
                    setQuizScores(scores);
                })
                .catch(error => console.error('Error fetching quiz scores:', error));
        }
    }, [user]);



    const handleQuizSelection = (quizTitle) => {
        const titleSlug = quizTitle.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        navigate(`/dashboard/quiz/${titleSlug}`);
    };

    const handleLessonOpen = (moduleId) => {
        navigate(`/dashboard/lessons/${moduleId}`);
    };

/*
    return (
        <div className="list-container">
        <div className="dashboard-navigation-buttons">
            <div className="dashboard-back-button-container">
                <Link to="/" className="dashboard-back-button">Back</Link>
            </div>
            <div className="dashboard-home-button-container">
                <Link to="/" className="dashboard-home-button">reventt</Link>
            </div>
        </div>
            <div className="quiz-table-container">
                <h2 className="dashboard-header">Dashboard</h2>
                <div className="quiz-table">
                    <div className="quiz-table-header">
                        <div className="quiz-title-header">Content</div>
                        <div className="quiz-score-header">Best Score</div>
                    </div>
                    {quizzes.map(quiz => (
                        <div key={quiz._id} className="quiz-table-row">
                            <div className="quiz-title-cell">
                                <button className="dashboard-button" onClick={() => handleQuizSelection(quiz.title)}>
                                    {quiz.title}
                                </button>
                            </div>
                            <div className="quiz-score-cell">
                                {quizScores[quiz._id] !== undefined ? `${quizScores[quiz._id]}%` : 'N/A'}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );*/
/* works perfect
    return (
        <div className="list-dashboard-section">
            <div className="dashboard-navigation-buttons">
                <Link to="/" className="dashboard-back-button">Back</Link>
                <Link to="/" className="dashboard-home-button">reventt</Link>
            </div>
            <h1 className="list-dashboard-header">Dashboard</h1>
            <div className="list-modules-container">
            {modulesData.map((module, index) => (
                <div key={index} className={`list-module ${activeModule === module.title ? 'list-module-open' : ''}`} onClick={() => toggleModule(module.title)}>
                <div className="list-module-title-wrapper"> 
                <div className="list-module-title">
                {index + 1}. {module.title}
                </div>
                <i className={`list-caret fa ${activeModule === module.title ? 'fa-angle-up' : 'fa-angle-down'}`} />
                </div>
                    {activeModule === module.title && (
                        <div className="list-module-description">
                        <button 
                            className="list-dashboard-button" 
                            onClick={() => handleLessonOpen(`module${index + 1}`)}>
                            Open {module.title} Lesson
                        </button>
                    {quizzes.filter(quiz => quiz.module?.title === module.title).map(quiz => (
                    <button key={quiz._id} className="list-dashboard-button" onClick={() => handleQuizSelection(quiz.title)}>
                    {quiz.title} - Best Score: {quizScores[quiz._id] !== undefined ? `${quizScores[quiz._id]}%` : 'N/A'}
                    </button>
                    ))}
                    </div>
                )}
                </div>
            ))}

            </div>
        </div>*/
    
        return (
        <div className="list-dashboard-section">
            <div className="dashboard-navigation-buttons">
                <Link to="/" className="dashboard-back-button">Back</Link>
                <Link to="/" className="dashboard-home-button">reventt</Link>
            </div>
            <h1 className="list-dashboard-header">Dashboard</h1>
<div className="list-modules-container">
    {modulesData.map((module, index) => (
        <div key={index} className={`list-module ${activeModule === module.title ? 'list-module-open' : ''}`} onClick={() => toggleModule(module.title)}>
            <div className="list-module-title-wrapper">
                <div className="list-module-title">
                    {index + 1}. {module.title}
                </div>
                <i className={`list-caret fa ${activeModule === module.title ? 'fa-angle-up' : 'fa-angle-down'}`} />
            </div>
            {activeModule === module.title && (
                <div className="list-module-content">
                    <div className="module-table">
                        <div className="module-table-row">
                            <div className="module-table-header">Lesson</div>
                            <div className="module-table-cell">
                                <button 
                                    className="list-dashboard-button" 
                                    onClick={() => handleLessonOpen(`module${index + 1}`)}>
                                    {module.title} Lesson
                                </button>
                            </div>
                        </div>
                        <div className="module-table-row">
                            <div className="module-table-header">Quizzes</div>
                            <div className="module-table-cell">
                                {quizzes.filter(quiz => quiz.module?.title === module.title).map(quiz => (
                                    <button key={quiz._id} className="list-dashboard-button" onClick={() => handleQuizSelection(quiz.title)}>
                                        {quiz.title} Quiz
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    ))}
</div>

        </div>
    );
}

export default List;
