import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module3Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);
    const [showSolution6, setShowSolution6] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 3: Control Flow</h1>

            <h2 className="lesson-h2">Understanding Control Flow</h2>
            <p className="lesson-p">Control flow in Python refers to the order in which the program's code is executed. Python provides several constructs to control the flow of execution, enabling decision-making, looping, and branching in your code.</p>

            <h3 className="lesson-h3">Conditional Statements</h3>
            <p className="lesson-p">Conditional statements allow the execution of different code depending on whether a condition is true or false.</p>
            <ul>
                <li><strong>if:</strong> Executes a block of code if a specified condition is true.</li>
                <li><strong>elif:</strong> Checks another condition if the previous conditions were not true.</li>
                <li><strong>else:</strong> Executes a block of code if none of the previous conditions were true.</li>
            </ul>

            <pre style={style.codeBlock}>
                {`x = 10
if x > 5:
    print("x is greater than 5")
elif x == 5:
    print("x is 5")
else:
    print("x is less than 5")
# Output: x is greater than 5`}
            </pre>


<h3 className="lesson-h3">Loops</h3>
<p className="lesson-p">Loops are a fundamental concept in programming, allowing you to execute a block of code repeatedly. Python offers different types of loops, each serving a specific purpose.</p>

<ul>
    <li>
        <strong>for Loop:</strong> The for loop in Python is used to iterate over a sequence, such as a string, a range of numbers, or other iterable objects. The structure of a for loop begins with the keyword 'for', followed by a variable name that represents the current item in the sequence, the keyword 'in', and then the sequence itself.
        <pre style={style.codeBlock}>
            {`for item in sequence:
    # actions to perform`}
        </pre>
        The 'range()' function is commonly used with for loops. It can take one, two, or three parameters: 
        <ul>
            <li>One parameter: `range(stop)` - generates a sequence of numbers from 0 to stop-1.</li>
            <li>Two parameters: `range(start, stop)` - generates a sequence from start to stop-1.</li>
            <li>Three parameters: `range(start, stop, step)` - generates a sequence from start to stop-1, incrementing by step.</li>
        </ul>
        Here are examples for each:
        <pre style={style.codeBlock}>
            {`# range() with one parameter
for i in range(3):
    print(i, end=' ')
# Output: 0 1 2

# range() with two parameters
for i in range(1, 4):
    print(i, end=' ')
# Output: 1 2 3

# range() with three parameters
for i in range(1, 10, 2):
    print(i, end=' ')
# Output: 1 3 5 7 9

# Iterating through a string
for char in 'Hello':
    print(char, end=' ')
# Output: H e l l o`}
        </pre>
    </li>
    <br/>
    <li>
        <strong>while Loop:</strong> The while loop repeatedly executes a block of code as long as a given condition is true. It's useful when you need to perform an action until a certain condition changes, like waiting for user input or processing data until a termination condition is met. The structure of a while loop starts with the keyword 'while', followed by a condition that evaluates to True or False. If the condition is True, the code inside the loop executes. After each iteration, the condition is evaluated again, and if it remains true, the loop continues.
        <pre style={style.codeBlock}>
            {`while condition:
    # actions to perform`}
        </pre>
        In a while loop, it's crucial to ensure that the condition eventually becomes False, or else you'll create an infinite loop where the code block keeps executing indefinitely. Here's an example:
        <pre style={style.codeBlock}>
            {`count = 3
while count > 0:
    print(count, end=' ')
    count -= 1 # same as count = count - 1
# Output: 3 2 1`}
        </pre>
    </li>
</ul>


<h3 className="lesson-h3">Break and Continue</h3>
<p className="lesson-p">In Python, the <strong>break</strong> and <strong>continue</strong> statements are used to alter the flow of a loop. Understanding these control statements is crucial for managing the execution of loops more effectively.</p>

<ul>
    <li>
        <strong>break:</strong> The 'break' statement is used to terminate the loop prematurely. When Python encounters a 'break' statement, it immediately exits the loop, regardless of whether the loop's condition is True or not. This is particularly useful when you are searching for an item in a collection and want to stop the loop once the item is found, or when you want to exit a loop based on a specific condition.
        <pre style={style.codeBlock}>
            {`# Example of break
for num in range(5):
    if num == 3:
        break
    print(num, end=' ')
# Output: 0 1 2 `}
        </pre>
        In this example, the loop is terminated as soon as 'num' equals 3. The numbers 0, 1, and 2 are printed on the same line, but 3 and 4 are not since the loop stops executing at 'num == 3'.
    </li><br/>
    <li>
        <strong>continue:</strong> The 'continue' statement is used to skip the rest of the code inside a loop for the current iteration only. When a 'continue' statement is encountered, Python skips the remaining statements in the loop block and goes back to the loop's start for the next iteration. This is useful for skipping specific items in a collection, like filtering out certain values.
        <pre style={style.codeBlock}>
            {`# Example of continue
for num in range(5):
    if num == 3:
        continue
    print(num, end=' ')
# Output: 0 1 2 4 `}
        </pre>
        Here, when 'num' equals 3, the 'continue' statement causes the loop to skip printing 3 and move to the next iteration, resulting in the numbers 0, 1, 2, and 4 being printed on the same line.
    </li>
</ul>



<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Try these exercises to practice your understanding of Python control flow.</p>
<ol>
    <li>
        Write a Python script that prints "Even" if a variable x is an even number and "Odd" if it's odd.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
x = 4
if x % 2 == 0:
    print("Even")
else:
    print("Odd")
# Output: Even`}
        </pre>}
    </li>
    <li>
        Create a Python script that prints numbers from 1 to 5, using a while loop.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
i = 1
while i <= 5:
    print(i, end=' ')
    i += 1
# Output: 1 2 3 4 5`}
        </pre>}
    </li>
    <li>
        Write a Python script that prints even numbers from 2 to 10, using a for loop. Make sure the numbers are printed on the same line.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
for num in range(2, 11, 2):
    print(num, end=' ')
# Output: 2 4 6 8 10`}
        </pre>}
    </li>
    <li>
        Write a Python script that prints all numbers from 1 to 10, except for 5, using a for loop.
        <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution4 && <pre style={style.codeBlock}>
            {`# Solution
for num in range(1, 11):
    if num == 5:
        continue
    print(num, end=' ')
# Output: 1 2 3 4 6 7 8 9 10`}
        </pre>}
    </li>
    <li>
        Create a script that uses a while loop to print "Python" 3 times.
        <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution5 && <pre style={style.codeBlock}>
            {`# Solution
count = 3
while count > 0:
    print("Python", end=' ')
    count -= 1
# Output: Python Python Python`}
        </pre>}
    </li>
    <li>
        Write a script to calculate the sum of numbers from 1 to 10 using a for loop and print the result.
        <button onClick={() => setShowSolution6(!showSolution6)} className="lesson-button">{showSolution6 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution6 && <pre style={style.codeBlock}>
            {`# Solution
sum = 0
for num in range(1, 11):
    sum += num
print(sum)
# Output: 55`}
        </pre>}
    </li>
</ol>

            <h2 className="lesson-h2">Recap of Module 3 and Preview of Module 4</h2>
            <p className="lesson-p">In this module, you've learned about the control flow in Python, including conditional statements, loops, and how to control the flow of your program. These concepts are fundamental in creating efficient and effective Python programs.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module3Lesson;
