import React, { useState } from 'react';
import './Topics.css';
import 'font-awesome/css/font-awesome.min.css';

/*
const modulesData = [
    {
        title: 'Introduction to Python',
        description: 'Essential for understanding the basics and setting up the environment.'
    },
    {
        title: 'Basic Concepts and Operations',
        description: 'Fundamental building blocks of Python programming, including understanding different data types and basic operations.'
    },
    {
        title: 'Control Flow',
        description: 'Critical for writing programs that can make decisions (conditional statements) and perform repetitive tasks (loops).'
    },
    {
        title: 'Functions',
        description: 'Understanding functions is key for writing organized and reusable code.'
    },
    {
        title: 'Data Collections',
        description: 'Lists, tuples, and dictionaries are fundamental data structures in Python, used to store and manipulate data.'
    },
    {
        title: 'Strings and Their Manipulations',
        description: 'Strings are a fundamental data type in Python, and knowing how to manipulate them is crucial.'
    },
    {
        title: 'Basic File Handling',
        description: 'Reading from and writing to files is a common requirement in many Python programs.'
    },
    {
        title: 'Error and Exception Handling',
        description: 'Essential for writing robust programs that can handle unexpected errors.'
    },
    {
        title: 'Advanced Data Collections',
        description: 'Expanding knowledge on data structures, which is important for more complex data manipulation.'
    },
    {
        title: 'Modules and Packages',
        description: 'Understanding modules and packages is crucial for leveraging existing code and organizing larger projects.'
    },
    {
        title: 'Intermediate File Handling',
        description: 'Introduces handling more complex file formats which are common in real-world applications.'
    },
    {
        title: 'Object-Oriented Programming (OOP)',
        description: 'A key programming paradigm in Python that helps in managing larger codebases more effectively.'
    },
    {
        title: 'Functional Programming Concepts',
        description: 'Provides an alternative approach to problem-solving and complements OOP.'
    },
    {
        title: 'Python Standard Library Exploration',
        description: 'The vast standard library is one of Python’s greatest strengths, offering a wide range of functionalities.'
    },
    {
        title: 'Algorithms and Data Structures',
        description: 'Fundamental for understanding how to store data efficiently and solve problems effectively.'
    }
];*/

const modulesData = [
    {
        title: 'Introduction to Python',
        description: 'Essential for understanding the basics and setting up the environment. This module provides an overview of Python syntax and best practices for setup, including installing Python and setting up an IDE.',
        lessonCount: 1,
        quizCount: 1,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Basic Concepts and Operations',
        description: 'Fundamental building blocks of Python programming, including understanding different data types and basic operations. Learn to write simple Python scripts using basic data types like integers, floats, and strings.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Control Flow',
        description: 'Critical for writing programs that can make decisions (conditional statements) and perform repetitive tasks (loops). This module delves into the use of if-else statements, for and while loops, teaching you how to structure and manage the execution of code effectively in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 6
    },
    {
        title: 'Functions',
        description: 'Understanding functions is key for writing organized and reusable code. This module covers how to define and call functions, parameter passing, return values, and basic scope rules.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 6
    },
    {
        title: 'Data Collections',
        description: 'Lists, tuples, and dictionaries are fundamental data structures in Python, used to store and manipulate data. Learn how to create, access, and manipulate these collections to effectively handle data.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Strings and Their Manipulations',
        description: 'Strings are a fundamental data type in Python, and knowing how to manipulate them is crucial. Explore string operations, formatting, and common methods to process textual data.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Basic File Handling',
        description: 'Reading from and writing to files is a common requirement in many Python programs. Learn to handle files with Python, including reading, writing, and understanding file handling modes.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Error and Exception Handling',
        description: 'Essential for writing robust programs that can handle unexpected errors. This module introduces try-except blocks and handling multiple exceptions for better error management.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Advanced Data Collections',
        description: 'Expanding knowledge on data structures, which is important for more complex data manipulation. Delve deeper into sets, frozen sets, and how to utilize advanced data structures.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Modules and Packages',
        description: 'Understanding modules and packages is crucial for leveraging existing code and organizing larger projects. Learn how to create modular code, use standard modules, and understand the concept of packages for code organization.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Intermediate File Handling',
        description: 'Introduces handling more complex file formats which are common in real-world applications. Gain skills in working with binary files, JSON, XML, and other common data formats in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Object-Oriented Programming (OOP)',
        description: 'A key programming paradigm in Python that helps in managing larger codebases more effectively. Learn about classes, objects, inheritance, polymorphism, and encapsulation in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Functional Programming Concepts',
        description: 'Provides an alternative approach to problem-solving and complements OOP. Understand the principles of functional programming, including lambda functions, map, filter, and reduce.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Python Standard Library Exploration',
        description: 'The vast standard library is one of Python’s greatest strengths, offering a wide range of functionalities. Explore various modules in the standard library to enhance your Python applications.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Algorithms and Data Structures',
        description: 'Fundamental for understanding how to store data efficiently and solve problems effectively. Delve into classic algorithms, data structure design, and algorithmic problem-solving techniques.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    }
];



const Module = ({ number, title, description, lessonCount, quizCount, handsOnQuestionsCount }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModule = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`module ${isOpen ? 'open' : ''}`} onClick={toggleModule}>
            <div className="module-title">
                {number}. {title}
                <i className={`caret fa ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`} />
            </div>
            {isOpen && (
                <div className="module-description">
                    <div>{description}</div><br/>
                    <p><strong>What's Included in This Module:</strong></p><br/>
                    <div className="module-extra-info">
                        <p>- <strong>Lesson covering {title}</strong>, offering extraordinary detail and depth to ensure a comprehensive understanding of the topic.</p>
                        <p>- <strong>{handsOnQuestionsCount} hands-on coding questions</strong> included in this module, based on the <strong>{title}</strong> topic, to help you implement what you've learned into real coding problems. These questions are designed to enhance your practical skills by applying theoretical concepts to tangible coding exercises. If you get stuck, you have the option to reveal the solution, ensuring a learning experience that supports your progress at every step.</p>
                        <p>- <strong>{quizCount} {quizCount === 1 ? 'quiz' : 'quizzes'}</strong> included in this module to reinforce your knowledge of the <strong>{title}</strong> topic.</p>
                    </div>
                </div>
            )}
        </div>
    );
};


const Topics = () => {
    return (
        <div className="modules-section">
            <h1 className="modules-header-text">Curriculum Breakdown</h1>
            <div className="modules-list">
                {modulesData.map((module, index) => (
                    <Module key={index} number={index + 1} title={module.title} description={module.description} lessonCount={module.lessonCount} quizCount={module.quizCount} handsOnQuestionsCount={module.handsOnQuestionsCount} />
                ))}
            </div>
        </div>
    );
};


export default Topics;
