import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module14Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 14: Exploring the Python Standard Library</h1>

            <h2 className="lesson-h2">Introduction to the Python Standard Library</h2>
            <p className="lesson-p">The Python Standard Library is a collection of modules and packages that come pre-installed with Python. It provides a wide range of functionalities, from mathematical operations to file handling and web development tools.</p>

<h2 className="lesson-h2">Mathematics and Random Number Generation</h2>
<p className="lesson-p">The <code>math</code> and <code>random</code> modules in Python are invaluable for tasks that involve mathematical computations and generating random numbers. The <code>math</code> module includes a variety of mathematical functions and constants, such as trigonometric functions and constants like pi and e. The <code>random</code> module, on the other hand, is used for creating random data, which is particularly useful in simulations, testing, and gaming applications.</p>
<pre style={style.codeBlock}>
    {`import math
import random

# math module examples
print(math.pi)  # Output: Value of pi
print(math.sqrt(16))  # Output: 4.0
print(math.sin(math.pi/2))  # Output: 1.0 (sin of 90 degrees)

# random module examples
print(random.randint(1, 10))  # Random integer between 1 and 10
random_list = [1, 2, 3, 4, 5]
random.shuffle(random_list)
print(random_list)  # Output: Randomly shuffled list
print(random.choice(random_list))  # Randomly select an item from the list`}
</pre>


<h3 className="lesson-h3">Working with Collections Module</h3>
<p className="lesson-p">
    The <code>collections</code> module in Python provides alternative container datatypes, which are enhancements to the standard containers like lists, tuples, and dictionaries. This module includes specialized classes like <code>Counter</code>, <code>defaultdict</code>, and <code>namedtuple</code> that offer additional functionality and ease certain programming tasks. For example, <code>Counter</code> is used for counting hashable objects, <code>defaultdict</code> provides default values for missing keys, and <code>namedtuple</code> generates tuple subclasses with named fields, making code more readable and maintainable.
</p>
<pre style={style.codeBlock}>
    {`from collections import Counter, defaultdict, namedtuple

# Counter
counts = Counter(['apple', 'banana', 'apple'])
print(counts)  # Output: Counter({'apple': 2, 'banana': 1})

# defaultdict
dd = defaultdict(int)
dd['apple'] += 1
print(dd)  # Output: defaultdict(<class 'int'>, {'apple': 1})

# namedtuple
Point = namedtuple('Point', ['x', 'y'])
pt = Point(1, 2)
print(pt.x, pt.y)  # Output: 1 2`}
</pre>


<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Enhance your understanding of the Python Standard Library with these exercises.</p>
<ol>
    <li>
        Calculate the circumference of a circle with a radius of 5 using the <code>math</code> module. The formula for the circumference is 2 * pi * radius.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`import math
radius = 5
circumference = 2 * math.pi * radius
print("Circumference:", circumference)
# Output: Circumference: 31.41592653589793`}
        </pre>}
    </li>
    <li>
        Using the <code>random</code> module, generate a list of five random numbers between 10 and 50, and then find the maximum number in the list.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`import random
random_numbers = [random.randint(10, 50) for _ in range(5)]
print("Random Numbers:", random_numbers)
print("Maximum Number:", max(random_numbers))
# Output will vary due to random generation`}
        </pre>}
    </li>
    <li>
        Create a `namedtuple` named 'Book' with fields 'title' and 'author', and instantiate it with the title '1984' and author 'George Orwell'. Then, print the title of the book.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`from collections import namedtuple
Book = namedtuple('Book', ['title', 'author'])
book = Book('1984', 'George Orwell')
print("Book Title:", book.title)
# Output: Book Title: 1984`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 14 and Preview of Module 15</h2>
            <p className="lesson-p">This module provided an overview of the Python Standard Library, showcasing its diverse functionalities and how they can be applied in various programming tasks. Understanding and utilizing these libraries is essential for efficient Python programming.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module14Lesson;
