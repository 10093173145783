import './Cards.css';
import { useNavigate } from 'react-router-dom';

const Cards = () => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate('/pricing');
    };

    return (

    <section>
            <div className="container">
                <div className="card-animation-text">
                    <h1>What We Offer</h1>
                </div>
            </div>

            <div className="card-box">
            
                <div className="content">

                    <div className="card" onClick={handleCardClick} tabIndex="0" role="button">
                
                        <div className="icon"><i className="material-icons md-36">computer</i></div>
                        <p className="title">Comprehensive Coding Curriculum</p>
                        <p className="text">Our beginner-friendly coding curriculum builds a strong foundation.</p>
                
                    </div>

                    <div className="card" onClick={handleCardClick} tabIndex="0" role="button">
                
                        <div className="icon"><i className="material-icons md-36">code</i></div>
                        <p className="title">Hands-On Coding <br />Exercises</p>
                        <p className="text">Solve coding problems to practically apply Python programming concepts.</p>
                
                    </div>

                    <div className="card" onClick={handleCardClick} tabIndex="0" role="button">
                
                        <div className="icon"><i className="material-icons md-36">quiz</i></div>
                        <p className="title">Module-Specific <br/>Quizzes</p>
                        <p className="text">Reinforce your learning with quizzes reflecting each lesson's content.</p>
                
                    </div>
                
                </div>

            </div>
        </section>

    );
}

export default Cards;
