import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module15Lesson = () => {
const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
  
      useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 15: Algorithms and Data Structures in Python</h1>

            <h2 className="lesson-h2">Understanding Algorithms and Data Structures</h2>
            <p className="lesson-p">Algorithms and data structures are fundamental to efficient problem solving and software development. In this module, we will delve into key algorithms and explore essential data structures such as stacks, queues, and trees. Our aim is to equip you with both the theoretical knowledge and practical skills necessary for effective programming in Python.</p>

<h2 className="lesson-h2">Data Structures in Python</h2>
<p className="lesson-p">Data structures are methods of organizing and storing data in a computer to access and modify it efficiently. The choice of data structure significantly affects the efficiency of an algorithm. In this module, we'll delve into several key data structures and their implementation in Python:</p>

<h3 className="lesson-h3">Array</h3>
<p className="lesson-p">Arrays, implemented as lists in Python, are collections of elements stored contiguously in memory and identified by indices. They allow fast access to elements but have a fixed size and require contiguous memory allocation. Python lists are dynamic arrays, meaning they can change in size and allow elements of different types.</p>

<h3 className="lesson-h3">Linked List</h3>
<p className="lesson-p">Linked lists consist of nodes linked sequentially. Each node contains data and a reference to the next node. They allow for dynamic memory allocation and efficient insertion and deletion of elements at any position. However, accessing elements requires sequential traversal, which can be slower than direct access in arrays.</p>

<h3 className="lesson-h3">Stack</h3>
<p className="lesson-p">Stacks are linear structures following the Last In First Out (LIFO) principle. Elements are added (pushed) and removed (popped) from the top of the stack. This structure is ideal for tasks like undo mechanisms, parsing expressions, and implementing function calls (call stack).</p>

<h3 className="lesson-h3">Queue</h3>
<p className="lesson-p">Queues are linear structures that follow the First In First Out (FIFO) principle. Elements are added at the rear and removed from the front. They are essential in scenarios like task scheduling, buffering, and breadth-first search in trees and graphs.</p>

<h3 className="lesson-h3">Tree</h3>
<p className="lesson-p">Trees are hierarchical structures with a root node and child nodes forming a branching structure. Binary trees, where each node has at most two children, are particularly common. Trees are used in various applications, including representing hierarchical data, organizing data for quick search, insertion, and deletion (Binary Search Trees), and decision-making (Decision Trees).</p>

<h3 className="lesson-h3">Graph</h3>
<p className="lesson-p">Graphs are collections of nodes (or vertices) connected by edges. They are used to represent and solve problems involving networks, like social networks, utility networks, and web page link structures. Graphs can be directed or undirected and may contain cycles.</p>

<h3 className="lesson-h3">Hash Table/Hash Map</h3>
<p className="lesson-p">Hash tables, known as dictionaries in Python, are associative arrays that map keys to values. They use a hash function to convert keys into indices of an array. Hash tables offer fast lookup, insertion, and deletion but require handling of hash collisions and dynamic resizing.</p>

<h3 className="lesson-h3">Heap</h3>
<p className="lesson-p">Heaps are complete binary trees satisfying the heap property: each node is smaller (or larger) than its children. This property makes heaps efficient for implementing priority queues, where the highest (or lowest) priority element is always at the root, allowing quick access to it.</p>

<p className="lesson-p">Each of these data structures has its specific use cases and trade-offs in terms of performance, memory usage, and ease of use. Mastering them enables you to choose the most appropriate structure for your particular problem in Python.</p>

            

            <h2 className="lesson-h2">Algorithms in Python</h2>
<p className="lesson-p">Algorithms are a set of instructions or steps to accomplish a specific task. They are central to computer science and programming. Understanding various algorithms and their applications is essential for solving problems efficiently. In this section, we'll explore several fundamental algorithms and their purposes:</p>

<h3 className="lesson-h3">Quick Sort</h3>
<p className="lesson-p">Quick Sort is a highly efficient sorting algorithm that uses a divide and conquer approach. It selects a 'pivot' element from the array and partitions the other elements into two sub-arrays, according to whether they are less than or greater than the pivot. The sub-arrays are then sorted recursively.</p>

<h3 className="lesson-h3">Merge Sort</h3>
<p className="lesson-p">Merge Sort is another divide and conquer algorithm. It divides the input array into two halves, calls itself for the two halves, and then merges the two sorted halves. This algorithm is particularly effective for large data sets.</p>

<h3 className="lesson-h3">Heap Sort</h3>
<p className="lesson-p">Heap Sort is a comparison-based sorting algorithm. It builds a heap from the input data, then repeatedly extracts the maximum element from the heap and rebuilds the heap until all elements are sorted.</p>

<h3 className="lesson-h3">Insertion Sort</h3>
<p className="lesson-p">Insertion Sort is a simple sorting algorithm that builds the final sorted array one element at a time. It is efficient for small data sets and is used as part of more complex algorithms like quick sort.</p>

<h3 className="lesson-h3">Selection Sort</h3>
<p className="lesson-p">Selection Sort divides the input list into two parts: a sorted sublist of items and a remaining sublist of unsorted items. It repeatedly removes the smallest element of the unsorted sublist and appends it to the sorted sublist.</p>

<h3 className="lesson-h3">Bubble Sort</h3>
<p className="lesson-p">Bubble Sort is a simple sorting algorithm that repeatedly steps through the list, compares adjacent elements, and swaps them if they are in the wrong order. It is named for the way smaller elements "bubble" to the top of the list.</p>

<h3 className="lesson-h3">Binary Search</h3>
<p className="lesson-p">Binary Search is used to find the position of a target value within a sorted array. It compares the target value to the middle element of the array; if they are not equal, the half in which the target cannot lie is eliminated and the search continues on the remaining half until the target is found.</p>

<h3 className="lesson-h3">Dijkstra's Algorithm</h3>
<p className="lesson-p">Dijkstra's Algorithm finds the shortest paths from a single node to all other nodes in a graph, which may represent, for example, road networks. It’s widely used in GPS devices for finding the shortest path.</p>

<h3 className="lesson-h3">Breadth-First Search (BFS)</h3>
<p className="lesson-p">Breadth-First Search is an algorithm for traversing or searching tree or graph data structures. It starts at the tree root and explores all neighbor nodes at the present depth before moving to the nodes at the next depth level.</p>

<h3 className="lesson-h3">Depth-First Search (DFS)</h3>
<p className="lesson-p">Depth-First Search is another method for traversing or searching a tree or graph. It starts at the root and explores as far as possible along each branch before backtracking. This algorithm is used in many types of graph-related problems.</p>

<h3 className="lesson-h3">Prim’s Algorithm</h3>
<p className="lesson-p">Prim’s Algorithm is a greedy algorithm that finds a minimum spanning tree for a weighted undirected graph. It grows the spanning tree by adding the cheapest possible connection from the tree to another vertex.</p>

<h3 className="lesson-h3">Kruskal’s Algorithm</h3>
<p className="lesson-p">Kruskal’s Algorithm is a greedy algorithm that finds a minimum spanning tree for a connected weighted graph. It finds a subset of the edges that forms a tree that includes every vertex, where the total weight of all the edges in the tree is minimized.</p>

<h3 className="lesson-h3">Bellman-Ford Algorithm</h3>
<p className="lesson-p">The Bellman-Ford Algorithm computes shortest paths from a single source vertex to all of the other vertices in a weighted digraph. It is slower than Dijkstra's algorithm but more versatile, as it is capable of handling graphs in which some of the edge weights are negative numbers.</p>

<h3 className="lesson-h3">Floyd-Warshall Algorithm</h3>
<p className="lesson-p">The Floyd-Warshall Algorithm is an algorithm for finding shortest paths in a weighted graph with positive or negative edge weights (without any negative cycles). A single execution of the algorithm will find the lengths of the shortest paths between all pairs of vertices.</p>

<p className="lesson-p">These algorithms cover various aspects of problem-solving, from organizing and manipulating data with sorting techniques to finding paths and connections in graph structures. Understanding and applying these algorithms in Python programming can significantly enhance problem-solving skills and computational efficiency.</p>

            
            <h2 className="lesson-h2">Time and Space Complexity</h2>
<p className="lesson-p">Time and space complexity are critical concepts in computer science that help evaluate the efficiency of algorithms. Time complexity refers to the amount of time an algorithm takes to complete as a function of the length of the input. Space complexity refers to the amount of memory space an algorithm uses in relation to the input size. Understanding these complexities is vital for selecting the right algorithm for a problem, especially for large datasets. Here's an overview of the time and space complexities for the algorithms we discussed:</p>

<table style={{ borderCollapse: 'collapse', width: '100%' }}>
  <tr>
    <th style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Algorithm</th>
    <th style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Time Complexity</th>
    <th style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Space Complexity</th>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Quick Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Average: O(n log n)<br/>Worst: O(n²)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(log n)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Merge Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(n log n)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(n)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Heap Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(n log n)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(1)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Insertion Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Average: O(n²)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(1)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Selection Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(n²)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(1)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Bubble Sort</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Average: O(n²)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(1)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Binary Search</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(log n)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(1)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Dijkstra's Algorithm</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Unoptimized: O(V²)<br/> Priority Queue: O(E + V log V)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Breadth-First Search (BFS)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V + E)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Depth-First Search (DFS)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V + E)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Prim’s Algorithm</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Unoptimized: O(V²)<br/> Priority Queue: O(E + V log V)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Kruskal’s Algorithm</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(E log E)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(E + V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Bellman-Ford Algorithm</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(VE)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V)</td>
  </tr>
  <tr>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>Floyd-Warshall Algorithm</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V³)</td>
    <td style={{ border: '1px solid black', padding: '8px', color: 'black' }}>O(V²)</td>
  </tr>
</table>

<p className="lesson-p">It's important to note that the 'V' stands for the number of vertices and 'E' stands for the number of edges in a graph. For sorting algorithms, 'n' represents the number of elements in the array. The complexities can vary based on the implementation and the nature of the input data.</p>

<p className="lesson-p">Understanding these complexities allows you to predict how an algorithm behaves as the input size grows, which is essential for writing efficient code. It's a crucial aspect of algorithmic design and analysis, especially in performance-critical applications.</p>

            <h2 className="lesson-h2">Conclusion</h2>
            <p className="lesson-p">This module has provided a comprehensive overview of algorithms and data structures, crucial for effective problem-solving and efficient programming in Python. We have explored a variety of fundamental data structures and algorithms, delving into their practical implementations and theoretical underpinnings. Understanding the intricacies of these concepts is vital for any aspiring Python programmer, as they form the backbone of complex software development and algorithmic problem solving. With the knowledge gained from this module, you are now better equipped to approach Python programming tasks with a deeper understanding of the underlying mechanisms that drive effective and efficient coding solutions.</p>
        </div>
        
                    <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module15Lesson;
