import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './ArticleList.css';

const ArticlesList = () => {
  const articles = [
    { id: 'should-i-learn-to-code', title: 'Should I Learn to Code?' },
    { id: 'why-learn-python-in-2024', title: 'Why Learn Python in 2024?' }
  ];

  return (
      <div className="list-of-articles-container">
        <Helmet>
            <title>List of Programming Articles | Reventt</title>
            <meta
                name="description"
                content="Explore a list of programming articles covering various topics related to coding, technology, and software development on reventt.com."
            />
        </Helmet>
        <div className="list-of-articles-content">
      <h1 className="list-of-articles-h1">List of Programming Articles</h1>
        <ul className="list-of-articles-ul">
            {articles.map(article => (
            <li className="list-of-articles-li" key={article.id}>
                <Link className="list-of-articles-link" to={`/article/${article.id}`}>{article.title}</Link>
            </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ArticlesList;
