import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Navbar from './components/Navbar'
import Cards from './components/Cards'
import Globe from './components/Globe'
import Comparison from './components/Comparison'
import Detailcards from './components/Detailcards'
import Footer from './components/Footer'
import QuizComponent from './components/QuizComponent'
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import List from './components/List';
import Modal from './components/Modal'
import Glassmorphism from './components/Glassmorphism'
import Topics from './components/Topics'
import Module1Lesson from './components/Module1Lesson';
import Module2Lesson from './components/Module2Lesson';
import Module3Lesson from './components/Module3Lesson';
import Module4Lesson from './components/Module4Lesson';
import Module5Lesson from './components/Module5Lesson';
import Module6Lesson from './components/Module6Lesson';
import Module7Lesson from './components/Module7Lesson';
import Module8Lesson from './components/Module8Lesson';
import Module9Lesson from './components/Module9Lesson';
import Module10Lesson from './components/Module10Lesson';
import Module11Lesson from './components/Module11Lesson';
import Module12Lesson from './components/Module12Lesson';
import Module13Lesson from './components/Module13Lesson';
import Module14Lesson from './components/Module14Lesson';
import Module15Lesson from './components/Module15Lesson';
import Pricing from './components/Pricing';
import Success from './components/Success';
import Login from './components/Login';
import SubscriptionManagement from './components/SubscriptionManagement';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import ArticlesList from './articles/ArticleList';
import Article1 from './articles/Article1';
import Article2 from './articles/Article2';

import { GoogleOAuthProvider } from '@react-oauth/google';

if (process.env.NODE_ENV === 'production') {
    console.log = function() {};
    console.error = function() {};
}

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Routes>
            <Route path="/" element={<div>
              <Navbar />
              <Header />
              <Cards />
              <Globe />
              <Detailcards />
              <Topics />
              <Glassmorphism />
              <Comparison />
              <Footer />
                </div>} />

                <Route path="/login" element={<div>
                  <Navbar />
                  <Login />
                </div>} />

                <Route path="/pricing" element={<div>
                  <Navbar />
                  <Pricing />
                  <Footer />
                </div>} />

                <Route path="/account-details" element={<div>
                  <Navbar />
                  <SubscriptionManagement />
                </div>} />

                <Route path="/payment-success" element={<div>
                  <Success />
                </div>} />

                <Route path="/terms-and-conditions" element={<div>
                  <Navbar />
                  <Terms />
                  <Footer />
                </div>} />

                <Route path="/privacy" element={<div>
                  <Navbar />
                  <Privacy />
                  <Footer />
                </div>} />

                <Route path="/articles" element={<div>
                  <Navbar />
                  <ArticlesList />
                  <Footer />
                </div>} />

                <Route path="/article/should-i-learn-to-code" element={<div>
                  <Navbar />
                  <Article1 />
                  <Footer />
                </div>} />

                <Route path="/article/why-learn-python-in-2024" element={<div>
                  <Navbar />
                  <Article2 />
                  <Footer />
                </div>} />

             <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<List />} />
                  <Route path="/dashboard/quiz/:quizTitle" element={<QuizComponent />} />
                  <Route path="/dashboard/lessons/module1" element={<Module1Lesson />} />
                  <Route path="/dashboard/lessons/module2" element={<Module2Lesson />} />
                  <Route path="/dashboard/lessons/module3" element={<Module3Lesson />} />
                  <Route path="/dashboard/lessons/module4" element={<Module4Lesson />} />
                  <Route path="/dashboard/lessons/module5" element={<Module5Lesson />} />
                  <Route path="/dashboard/lessons/module6" element={<Module6Lesson />} />
                  <Route path="/dashboard/lessons/module7" element={<Module7Lesson />} />
                  <Route path="/dashboard/lessons/module8" element={<Module8Lesson />} />
                  <Route path="/dashboard/lessons/module9" element={<Module9Lesson />} />
                  <Route path="/dashboard/lessons/module10" element={<Module10Lesson />} />
                  <Route path="/dashboard/lessons/module11" element={<Module11Lesson />} />
                  <Route path="/dashboard/lessons/module12" element={<Module12Lesson />} />
                  <Route path="/dashboard/lessons/module13" element={<Module13Lesson />} />
                  <Route path="/dashboard/lessons/module14" element={<Module14Lesson />} />
                  <Route path="/dashboard/lessons/module15" element={<Module15Lesson />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
        </div>
        </AuthProvider>
      </GoogleOAuthProvider>
  );
}

export default App;
