import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module4Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);
    const [showSolution6, setShowSolution6] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 4: Functions in Python</h1>

            <h2 className="lesson-h2">Introduction to Functions</h2>
            <p className="lesson-p">Functions are a fundamental aspect of Python programming, crucial for organizing and modularizing code. They allow you to encapsulate a set of instructions into reusable blocks, improving code readability and maintainability. By breaking complex tasks into smaller, manageable sub-tasks, functions enhance the efficiency of development.</p>

            <h3 className="lesson-h3">Defining and Calling Functions</h3>
            <p className="lesson-p">A function in Python is a reusable block of code that performs a specific action. Functions are defined using the <code style={style.code}>def</code> keyword, followed by a unique function name and a pair of parentheses. These parentheses can be empty if the function does not require any input. After defining a function, you can execute or 'call' it by using its name followed by parentheses. This allows for the separation of code into manageable sections and enhances code readability and reuse.</p>
            <pre style={style.codeBlock}>
                {`# Example 1: Simple greeting function
def greet():
    print("Hello, World!")
greet()  # Calling the function
# Output: Hello, World!

# Example 2: Function with an if statement
def check_number():
    num = 12
    if num > 10:
        print(num, "is greater than 10")
    else:
        print(num, "is not greater than 10")
check_number()  # Calling the function
# Output: 12 is greater than 10

# Example 3: Function with a for loop
def print_numbers():
    for i in range(5):
        print(i, end=' ')
print_numbers()  # Calling the function
# Output: 0 1 2 3 4 `}
            </pre>



            <h3 className="lesson-h3">Parameters and Arguments</h3>
            <p className="lesson-p">Parameters in functions allow for the passing of information into the function. They act as placeholders for values that are used within the function. When a function is called, actual values, known as arguments, are passed to these parameters. This enables functions to be more dynamic and adaptable to different inputs. Parameters can be used to pass data to the function, modify the function's behavior, or even control the flow of logic within the function.</p>
            <pre style={style.codeBlock}>
                {`# Example 1: Greeting function with a parameter
def greet(name):
    print("Hello, " + name)
greet("Alice")  # Passing 'Alice' as an argument
# Output: Hello, Alice

# Example 2: Function with a parameter and a for loop
def print_multiples(number):
    for i in range(1, 6):
        print(number * i, end=' ')
print_multiples(3)  # Passing 3 as an argument
# Output: 3 6 9 12 15

# Example 3: Function with a parameter to check if a number is prime
def check_prime(number):
    if number < 2:
        print(f"{number} is not a prime number.")
        return  # Exit the function early if number is less than 2
    for i in range(2, number):
        if number % i == 0:
            print(f"{number} is not a prime number.")
            return  # Exit the function early if a divisor is found
    print(f"{number} is a prime number.")

check_prime(7)  # Checking if 7 is a prime number
# Output: 7 is a prime number`}
            </pre>



            <h3 className="lesson-h3">Return Values</h3>
            <p className="lesson-p">Functions in Python can return values to the caller using the <code style={style.code}>return</code> statement. This feature is useful for producing a result from a function that can be stored in a variable, used in further calculations, or displayed to the user. The return value can be any data type, such as a number, string, or even complex objects. When a function completes its execution and reaches a return statement, it sends the specified value back to where the function was called and terminates.</p>
            <pre style={style.codeBlock}>
                {`# Example 1: Function returning a sum
def add(a, b):
    return a + b

result = add(5, 3)
print(result)  # Using the returned value
# Output: 8

# Example 2: Function returning a formatted string
def create_greeting(name):
    return f"Hello, {name}!"

greeting = create_greeting("Alice")
print(greeting)  # Using the returned value
# Output: Hello, Alice!`}
            </pre>


            <h2 className="lesson-h2">Practice Exercises</h2>
            <p className="lesson-p">Try these exercises to practice your understanding of Python functions.</p>
            <ol>
                <li>
                    Write a function named <code style={style.code}>multiply</code> that takes two numbers, x and y, and returns their product.
                    <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution1 && <pre style={style.codeBlock}>
                        {`# Solution
def multiply(x, y):
    return x * y

print(multiply(4, 5))  # Calling the function with arguments 4 and 5
# Output: 20`}
                    </pre>}
                </li>
                <li>
                    Create a function called <code style={style.code}>is_even</code> that checks if a given number, num, is even. If it is, return <code style={style.code}>True</code>, otherwise return <code style={style.code}>False</code>.
                    <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution2 && <pre style={style.codeBlock}>
                        {`# Solution
def is_even(num):
    return num % 2 == 0

print(is_even(10))  # True for 10
print(is_even(3))   # False for 3`}
                    </pre>}
                </li>
                <li>
                    Write a function named <code style={style.code}>greet_twice</code> that prints "Hello" twice.
                    <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution3 && <pre style={style.codeBlock}>
                        {`# Solution
def greet_twice():
    for i in range(2):
        print("Hello", end=' ')

greet_twice()  # Output: Hello Hello`}
                    </pre>}
                </li>
                <li>
                    Create a function named <code style={style.code}>is_prime</code> that checks whether a given number, num, is a prime number. The function should return <code style={style.code}>True</code> if the number is prime, otherwise <code style={style.code}>False</code>.
                    <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution4 && <pre style={style.codeBlock}>
                        {`# Solution
def is_prime(num):
    if num < 2:
        return False
    for i in range(2, num):
        if num % i == 0:
            return False
    return True

print(is_prime(7))  # True for 7
print(is_prime(4))  # False for 4`}
                    </pre>}
                </li>
                <li>
                    Create a function <code style={style.code}>count_down</code> that takes an integer, start, and prints a countdown from that number to 1.
                    <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution5 && <pre style={style.codeBlock}>
                        {`# Solution
def count_down(start):
    for i in range(start, 0, -1):
        print(i, end=' ')

count_down(5)  # Output: 5 4 3 2 1`}
                    </pre>}
                </li>
                <li>
                    Write a function <code style={style.code}>add_three</code> that takes three numbers as parameters and returns their sum.
                    <button onClick={() => setShowSolution6(!showSolution6)} className="lesson-button">{showSolution6 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution6 && <pre style={style.codeBlock}>
                        {`# Solution
def add_three(a, b, c):
    return a + b + c

print(add_three(1, 2, 3))  # Output: 6`}
                    </pre>}
                </li>
            </ol>





            <h2 className="lesson-h2">Recap of Module 4 and Preview of Module 5</h2>
            <p className="lesson-p">In this module, you've learned about functions in Python, how to define them, pass arguments, return values, and more. Functions are key to writing clean, efficient, and reusable code. As you progress, you'll encounter more complex use cases of functions, which are essential in Python programming.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module4Lesson;
