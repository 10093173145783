import './Detailcards.css';

const Detailcards = () => {
    return (
        /*
        <section className="detail-card-container-section">

            <div className="detail-card-text">
                <h1>About Our Platform</h1>
            </div>

            <div className="detail-card-container">
                <a className="detail-card" href="#">
                    <h3>Comprehensive Coding Curriculum</h3>
                    <p className="small">Our platform offers a structured, beginner-friendly coding curriculum. Start from scratch and progress step by step through programming concepts.</p>
                </a>
                <a className="detail-card" href="#">
                    <h3>Video Tutorials by Experts</h3>
                    <p className="small">Learn how to code from experts through high-quality video tutorials. Each concept is explained in-depth, making complex topics easy to understand.</p>
                </a>
                <a className="detail-card" href="#">
                    <h3>Interactive Code Editor</h3>
                    <p className="small">Practice coding directly in our integrated code editor. Write, run, and test your code in real-time as you learn.</p>
                </a>
                <a className="detail-card" href="#">
                    <h3>Coding Questions with Video Explanations</h3>
                    <p className="small">Enhance your coding skills by tackling a variety of coding questions, each accompanied by comprehensive video explanations. Master programming concepts through hands-on practice.</p>
                </a>
                <a className="detail-card" href="#">
                    <h3>Data Structures and Algorithms</h3>
                    <p className="small">Access videos breaking down data structures and algorithms. Gain the knowledge needed to excel in software engineering interviews at top tech companies.</p>
                </a>
                <a className="detail-card" href="#">
                    <h3>Track Your Progress</h3>
                    <p className="small">Keep tabs on your coding journey with our progress tracking feature, which shows you the coding questions you've completed and their difficulty levels.</p>
                </a>
            </div>
            
        </section>*/

        <section className="detail-card-container-section">

    <div className="detail-card-text">
        <h1 className="detail-card-text-h1">About Our Platform</h1>
    </div>

    <div className="detail-card-container">
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Comprehensive Coding Curriculum</h3>
            <p className="small detail-card-p">Explore a structured, beginner-friendly curriculum covering key programming concepts in Python, with a focus on practical application and understanding.</p>
        </a>
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Engaging, In-Depth Lessons</h3>
            <p className="small detail-card-p">Immerse yourself in richly crafted lessons that blend theory with practical examples, making complex Python concepts accessible and captivating.</p>
        </a>
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Practical Coding Challenges</h3>
            <p className="small detail-card-p">Enhance your learning with coding challenges for each lesson. Write code and refer to solutions as needed to deepen your understanding and reinforce key concepts.</p>
        </a>
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Module-End Quizzes</h3>
            <p className="small detail-card-p">Evaluate your understanding and retention of programming concepts with quizzes at the end of each module, including immediate feedback on your performance.</p>
        </a>
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Applied Coding Examples</h3>
            <p className="small detail-card-p">Explore programming concepts with applied coding examples that demonstrate how to translate theory into practice, enhancing your learning with actionable insights.</p>
        </a>
        <a className="detail-card" href="/pricing">
            <h3 className="detail-card-h3">Hands-On Coding Practice</h3>
            <p className="small detail-card-p">Build a solid foundation in Python with coding exercises that reinforce key concepts, fostering a comprehensive and practical understanding of programming.</p>
        </a>
    </div>
    
</section>

    );
}

export default Detailcards;
