import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module8Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};

          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 8: Error and Exception Handling in Python</h1>

            <h2 className="lesson-h2">Introduction to Error Handling</h2>
            <p className="lesson-p">Understanding how to handle errors and exceptions is crucial in Python programming. It helps in creating reliable and fault-tolerant applications. This module covers the basics of error handling, including try-except blocks, raising exceptions, and more.</p>

            <h3 className="lesson-h3">Basic Try-Except Block</h3>
            <p className="lesson-p">The try-except block in Python is a fundamental construct used to handle exceptions and errors that may occur during program execution. When code within a try block raises an exception, the program flow is immediately transferred to the except block, allowing for a planned response rather than a crash. This structure is essential for building robust applications that can anticipate and manage potential errors, such as input/output operations or arithmetic calculations. By using try-except blocks, programmers can maintain control over the program's behavior even in unexpected situations.</p>
            <pre style={style.codeBlock}>
                {`# Basic try-except example
try:
    # Code that might raise an exception
    result = 10 / 0
except ZeroDivisionError:
    print('Divided by zero!')
# Output: Divided by zero!`}
            </pre>

            <h3 className="lesson-h3">Handling Multiple Exceptions</h3>
            <p className="lesson-p">Python allows a single try block to handle multiple types of exceptions, which is particularly useful when a code segment may trigger various kinds of errors. By specifying multiple except blocks, each tailored to a specific exception type, the program can respond appropriately to different error scenarios. This approach ensures that your program is prepared for various exceptional conditions, enhancing its reliability and robustness. It also contributes to clearer and more organized error handling, as each exception type can be addressed separately within its own except block.</p>
            <pre style={style.codeBlock}>
                {`# Handling multiple exceptions
try:
    # Code that might raise different exceptions
    result = 10 / 'a'
except ZeroDivisionError:
    print('Divided by zero!')
except TypeError:
    print('Type error occurred!')
# Output: Type error occurred!`}
            </pre>

            <h3 className="lesson-h3">Using Else Clause in Try-Except Block</h3>
<p className="lesson-p">The 'else' clause in a try-except block adds an additional layer of logic to your error handling. This clause is executed only if no exceptions occur in the try block. It's particularly useful for code that you want to execute only if the try block was successful, without encountering any errors. This separation enhances code readability and maintains a clear distinction between normal execution and error handling. It also allows you to avoid inadvertently catching exceptions that weren't meant to be handled by the try block.</p>
<pre style={style.codeBlock}>
{`# Using else clause
try:
    number = int('10')
except ValueError:
    print('Not a valid number')
else:
    print('Valid number:', number)
# Output: Valid number: 10`}
</pre>


            <h3 className="lesson-h3">Finally Block</h3>
            <p className="lesson-p">The 'finally' block in Python plays a crucial role in exception handling. It is executed after the try and except blocks, regardless of whether an exception was raised or not. Its primary use is for resource management tasks, such as closing files or releasing network resources, ensuring that these operations are performed even if an error occurs. This ensures that your program can clean up resources properly and avoid potential resource leaks. The 'finally' block is a good practice for maintaining the integrity and stability of your program, especially when dealing with external resources.</p>
            <pre style={style.codeBlock}>
                {`# Using finally block
try:
    result = 10 / 2
except ZeroDivisionError:
    print('Divided by zero!')
finally:
    print('This code runs no matter what.')
# Output: This code runs no matter what.`}
            </pre>

            <h3 className="lesson-h3">Raising Exceptions</h3>
            <p className="lesson-p">Raising exceptions is a powerful feature in Python that allows you to explicitly trigger exceptions when certain conditions are met. This is particularly useful in situations where you need to enforce specific constraints or rules within your code. By using the <code>raise</code> keyword, you can generate custom error messages, making your code more informative and easier to debug. This approach is commonly used in functions to validate input arguments, ensuring that they meet the required criteria and alerting the user immediately when they do not. Raising exceptions helps in maintaining the integrity of the program and preventing unexpected behavior or data corruption.</p>
            <pre style={style.codeBlock}>
                {`# Raising an exception
def divide(x, y):
    if y == 0:
        raise ValueError('Cannot divide by zero!')
    return x / y

try:
    result = divide(10, 0)
except ValueError as e:
    print(e)
# Output: Cannot divide by zero!`}
            </pre>

<h3 className="lesson-h3">Understanding Exception Hierarchy</h3>
<p className="lesson-p">Understanding the hierarchy of exceptions in Python helps in structuring effective error handling:</p>
<ul>
    <li><strong>BaseException:</strong> At the top of the hierarchy, it's the base class for all exceptions. Direct descendants include 'SystemExit', 'KeyboardInterrupt', and 'Exception'.</li>
    <li><strong>Exception Class:</strong> This is where most standard exceptions are derived from, including 'ValueError', 'TypeError', and 'FileNotFoundError'.</li>
    <li><strong>Importance of Order:</strong> Catch more specific exceptions before general ones. For example, catch 'ValueError' before 'Exception' to handle specific errors precisely.</li>
    <li><strong>Cautious Use:</strong> Catching 'BaseException' is generally not recommended except for system-exiting exceptions.</li>
</ul>
<pre style={style.codeBlock}>
{`# Enhanced exception hierarchy example
try:
    # Code that might raise different exceptions
    ...
except ValueError:
    # Handle specific exception first
    ...
except Exception as e:
    # Catch most other types of exceptions
    print(f"An error occurred: {e}")
# Note: Catching 'BaseException' is not typically recommended unless handling system-exiting exceptions`}
</pre>

            
            <h3 className="lesson-h3">Exception Chaining</h3>
<p className="lesson-p">Exception chaining in Python is a feature that allows one exception to cause another. This is particularly useful when an exception occurs in the process of handling another exception, or when re-raising an exception with additional context. By using the 'from' keyword, Python links exceptions together, providing a comprehensive traceback that includes both the primary and secondary exceptions. This chaining provides a clear understanding of the sequence of events leading to the final error, aiding significantly in debugging.</p>
<pre style={style.codeBlock}>
{`# Demonstrating enhanced exception chaining
try:
    try:
        file = open('nonexistent_file.txt')
    except FileNotFoundError as e:
        # Handling the first exception and chaining with another
        raise RuntimeError('Failed to process file') from e
except RuntimeError as e:
    # Catching the chained exception
    print(f"Caught chained exception: {e.__cause__}")
# This will display information about both the RuntimeError and the original FileNotFoundError`}
</pre>




<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Apply the concepts you've learned in this module with these exercises.</p>
<ol>
    <li>
        Write a script that uses a try-except block to handle a FileNotFoundError when trying to open a file that does not exist. Print a custom error message in the except block.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
try:
    open('non_existing_file.txt', 'r')
except FileNotFoundError:
    print('The file does not exist.')
# Output: The file does not exist.`}
        </pre>}
    </li>
    <li>
        Create a script with a try-except-else block where you try to convert a string to an integer. Use the else block to print the result if no exception was raised.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
try:
    number = int("5")
except ValueError:
    print('Conversion failed.')
else:
    print('Conversion successful:', number)
# Output: Conversion successful: 5`}
        </pre>}
    </li>
    <li>
        Write a Python script that uses a try-except-finally block to open a file and print its content. Ensure the file is closed in the finally block.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
try:
    file = open('example.txt', 'r')
    print(file.read())
except FileNotFoundError:
    print('File not found.')
finally:
    file.close()
# Output will vary based on the contents of 'example.txt'`}
        </pre>}
    </li>
    <li>
        Create a function that raises an ArithmeticError if the given divisor is 1, and use a try-except block to catch this exception.
        <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution4 && <pre style={style.codeBlock}>
            {`# Solution
def divide_by(x, y):
    if y == 1:
        raise ArithmeticError('Divisor should not be 1')
    return x / y

try:
    result = divide_by(10, 1)
except ArithmeticError as e:
    print(e)
# Output: Divisor should not be 1`}
        </pre>}
    </li>
    <li>
        Write a script that demonstrates exception chaining by catching a KeyError and raising a custom exception with additional context.
        <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution5 && <pre style={style.codeBlock}>
            {`# Solution
try:
    try:
        data = {'key': 'value'}
        print(data['nonexistent_key'])
    except KeyError as e:
        raise RuntimeError('Key missing from dictionary') from e
except RuntimeError as e:
    print(f"Caught chained exception: {e.__cause__}")
# Output: Caught chained exception: KeyError('nonexistent_key')`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 8 and Preview of Module 9</h2>
            <p className="lesson-p">This module explored error and exception handling in Python, providing tools to create more stable and error-resistant programs. Understanding and utilizing these concepts is key to writing robust Python software.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module8Lesson;
