import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module12Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};

          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 12: Object-Oriented Programming in Python</h1>

            <h2 className="lesson-h2">Introduction to OOP</h2>
            <p className="lesson-p">Object-Oriented Programming (OOP) is a programming paradigm based on the concept of "objects", which can contain data and code. In Python, OOP uses classes and objects to create models based on the real world.</p>

            <h3 className="lesson-h3">Defining Classes and Creating Objects</h3>
            <p className="lesson-p">Classes in Python act as blueprints for creating objects, which are instances of these classes. Each class can have its own attributes (data) and methods (functions). The <code>__init__</code> method is a special method called a constructor, used for initializing an object's attributes. The <code>self</code> keyword represents the instance of the class and is used to access the attributes and methods of the class. It helps in differentiating between the class's methods and attributes and its local variables.</p>
            <ul>
    <li><strong>Classes</strong>: Define the structure and behavior of objects.</li>
    <li><strong>Objects</strong>: Instances of classes, representing real-world entities.</li>
    <li><strong>__init__ method</strong>: Special method to initialize new objects.</li>
    <li><strong>self</strong>: Refers to the object itself and is used to access class attributes and methods.</li>
</ul>
            <pre style={style.codeBlock}>
                {`# Defining a class
class Car:
    def __init__(self, brand, model):
        self.brand = brand
        self.model = model

    def display_info(self):
        print(f"Car: {self.brand} {self.model}")

# Creating an object
my_car = Car("Toyota", "Corolla")
my_car.display_info()
# Output: Car: Toyota Corolla`}
            </pre>

            <h3 className="lesson-h3">Inheritance in Python</h3>
            <p className="lesson-p">Inheritance is a mechanism in OOP that allows a new class, known as a derived or child class, to inherit properties and behaviors (attributes and methods) from an existing class, known as a base or parent class. This promotes code reusability and a hierarchical organization of classes. In Python, the derived class inherits the base class by specifying the base class name in its declaration. The <code>super()</code> function in the derived class is used to call the constructor of the base class, allowing it to initialize the base class attributes.</p>
            <ul>
    <li><strong>Base Class</strong>: The class whose properties are inherited.</li>
    <li><strong>Derived Class</strong>: The class that inherits properties from the base class.</li>
    <li><strong>super()</strong>: Used to call methods from the base class.</li>
    <li><strong>Reusability</strong>: Inheritance promotes code reuse and logical structure.</li>
</ul>
            <pre style={style.codeBlock}>
                {`# Base class
class Vehicle:
    def __init__(self, brand):
        self.brand = brand

# Derived class
class Truck(Vehicle):
    def __init__(self, brand, load_capacity):
        super().__init__(brand)
        self.load_capacity = load_capacity

truck = Truck("Ford", 5000)
print(truck.brand, truck.load_capacity)
# Output: Ford 5000`}
            </pre>

            <h3 className="lesson-h3">Encapsulation and Abstraction</h3>
            <p className="lesson-p">Encapsulation is the concept of bundling data (attributes) and methods (functions that operate on the data) into a single unit, or class. In Python, encapsulation is achieved by defining private attributes and methods, which are accessible only within the class. This is done using double underscores <code>__</code> before the attribute names (like <code>__balance</code>). Abstraction, on the other hand, involves hiding the complex implementation details and only exposing the necessary functionalities. It simplifies the interface of complex systems and reduces the impact of change.</p>
            <ul>
    <li><strong>Encapsulation</strong>: Combines data and methods in a single unit.</li>
    <li><strong>Private Attributes</strong>: Attributes not accessible outside the class.</li>
    <li><strong>Abstraction</strong>: Hides complex details and exposes only the necessary parts.</li>
    <li><strong>Simplification</strong>: Makes interaction with complex systems easier.</li>
</ul>
            <pre style={style.codeBlock}>
                {`# Encapsulation example
class Account:
    def __init__(self):
        self.__balance = 0  # Private attribute

    def deposit(self, amount):
        if amount > 0:
            self.__balance += amount

    def get_balance(self):
        return self.__balance

account = Account()
account.deposit(100)
print(account.get_balance())
# Output: 100`}
            </pre>

            <h2 className="lesson-h2">Practice Exercises</h2>
            <p className="lesson-p">Try these exercises to enhance your understanding of OOP in Python.</p>
            <ol>
                <li>
                    Define a class 'Book' with attributes 'title' and 'author', and a method to display book information.
                    <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution1 && <pre style={style.codeBlock}>
                        {`# Solution
class Book:
    def __init__(self, title, author):
        self.title = title
        self.author = author

    def display_info(self):
        print(f"Book: {self.title} by {self.author}")

book = Book("1984", "George Orwell")
book.display_info()
# Output: Book: 1984 by George Orwell`}
                    </pre>}
                </li>
                <li>
                    Create a subclass 'ElectricCar' from the 'Car' class and add an attribute 'battery_capacity'.
                    <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution2 && <pre style={style.codeBlock}>
                        {`# Solution
class ElectricCar(Car):
    def __init__(self, brand, model, battery_capacity):
        super().__init__(brand, model)
        self.battery_capacity = battery_capacity

tesla = ElectricCar("Tesla", "Model S", 100)
tesla.display_info()
print("Battery Capacity:", tesla.battery_capacity)
# Output: Car: Tesla Model S
# Battery Capacity: 100`}
                    </pre>}
                </li>
                <li>
                    Demonstrate encapsulation by creating a class 'Rectangle' with private attributes 'length' and 'width', and a method to calculate the area.
                    <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution3 && <pre style={style.codeBlock}>
                        {`# Solution
class Rectangle:
    def __init__(self, length, width):
        self.__length = length
        self.__width = width

    def area(self):
        return self.__length * self.__width

rectangle = Rectangle(5, 4)
print("Area:", rectangle.area())
# Output: Area: 20`}
                    </pre>}
                </li>
            </ol>

            <h2 className="lesson-h2">Recap of Module 12 and Preview of Module 13</h2>
            <p className="lesson-p">This module covered the fundamentals of Object-Oriented Programming in Python, including classes, objects, inheritance, encapsulation, and abstraction. These concepts are essential for managing complex codebases and developing modular, scalable, and maintainable software.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module12Lesson;
