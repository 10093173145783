import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css'

const Module2Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false)
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>
        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 2: Basic Concepts and Operations</h1>

            <h2 className="lesson-h2">Variables in Python</h2>
<p className="lesson-p">Variables are essential in Python programming. They are used to store data that can be accessed and modified throughout your code. A variable in Python is like a labeled container for storing data. The name of the variable acts as a label to access the stored value. In Python, variables are dynamically typed, meaning you do not need to declare the type of the variable when you create one. You can simply assign a value to a variable, and Python automatically understands the type.</p>
<p className="lesson-p">Python also allows for multiple assignments in a single line, making it convenient to initialize multiple variables. For example, <code style={style.code}>a, b = 0, 1</code> simultaneously creates two variables, 'a' and 'b', and assigns them the values 0 and 1, respectively.</p>
<pre style={style.codeBlock}>
    # Creating variables<br/>
    x = 5<br/>
    message = "Hello"<br/>

    print(x)  # Output: 5<br/>
    print(message)  # Output: Hello<br/><br/>

    # Multiple assignments<br/>
    a, b = 0, 1<br/>
    print(a)  # Output: 0<br/>
    print(b)  # Output: 1<br/>
</pre>
<p className="lesson-p">Variables are mutable, meaning their values can change throughout the program. It's important to choose meaningful and descriptive names for variables to make your code more readable and maintainable.</p>


            <h2 className="lesson-h2">Data Types in Python</h2>
            <p className="lesson-p">Python supports several built-in data types. Understanding these is crucial as they are the building blocks of more complex data structures. Let's explore the primary data types:</p>
            
            <ul>
                <li><strong>Integers:</strong> Whole numbers without a decimal. Example: <code style={style.code}>5</code>, <code style={style.code}>-3</code></li>
                <li><strong>Floats:</strong> Numbers with a decimal point. Example: <code style={style.code}>3.14</code>, <code style={style.code}>-0.001</code></li>
                <li><strong>Strings:</strong> Sequence of characters enclosed in quotes. Example: <code style={style.code}>"Hello"</code>, <code style={style.code}>'World'</code></li>
                <li><strong>Booleans:</strong> Represents truth values: <code style={style.code}>True</code> or <code style={style.code}>False</code></li>
            </ul>

            <h3 className="lesson-h3">Integers and Floats</h3>
            <p className="lesson-p">Integers and floats are the two main numerical data types in Python. Integers are whole numbers, while floats represent fractional numbers.</p>
            <pre style={style.codeBlock}>
                x = 10  # Integer<br/>
                y = 3.14  # Float<br/>
                print(x * y)  # Multiplication<br/>
                # Output: 31.4
            </pre>

            <h3 className="lesson-h3">Strings</h3>
            <p className="lesson-p">Strings in Python are sequences of characters. They can be enclosed in single or double quotes.</p>
            <pre style={style.codeBlock}>
                greeting = "Hello"<br/>
                name = 'John'<br/>
                welcome = greeting + " " + name  # String concatenation<br/>
                print(welcome)<br/>
                # Output: Hello John
            </pre>

            <h3 className="lesson-h3">Booleans</h3>
            <p className="lesson-p">Booleans represent one of two values: <code style={style.code}>True</code> or <code style={style.code}>False</code>. They are often the result of comparison operations.</p>
            <pre style={style.codeBlock}>
                is_greater = 5 > 3<br/>
                print(is_greater)<br/>
                # Output: True
            </pre>

            <h2 className="lesson-h2">Basic Operations</h2>
            <p className="lesson-p">Python provides various operations that can be performed on data types. These include arithmetic operations like addition, subtraction, multiplication, and division, as well as logical operations like AND, OR, and NOT.</p>

            <h3 className="lesson-h3">Arithmetic Operations</h3>
            <pre style={style.codeBlock}>
                # Addition<br/>
                print(2 + 3)<br/>
                # Output: 5<br/><br/>

                # Subtraction<br/>
                print(5 - 2)<br/>
                # Output: 3<br/><br/>

                # Multiplication<br/>
                print(3 * 4)<br/>
                # Output: 12<br/><br/>

                # Division<br/>
                print(8 / 2)<br/>
                # Output: 4.0<br/><br/>

                # Floor Division<br/>
                print(8 // 3)<br/>
                # Output: 2<br/><br/>

                # Modulus (remainder)<br/>
                print(7 % 3)<br/>
                # Output: 1<br/><br/>

                # Exponentiation<br/>
                print(2 ** 3)<br/>
                # Output: 8
            </pre>


            <h3 className="lesson-h3">Logical Operations</h3>
            <p className="lesson-p">Logical operations in Python are used to combine conditional statements. They are essential for making decisions based on different conditions. There are three primary logical operators: AND, OR, and NOT.</p>

            <ul>
                <li><strong>AND:</strong> Returns True if both statements are true. For example, if both 'a' and 'b' are true, then 'a AND b' is True.</li>
                <li><strong>OR:</strong> Returns True if at least one of the statements is true. If either 'a' or 'b' is true, then 'a OR b' is True.</li>
                <li><strong>NOT:</strong> Returns True if the statement is false, and vice versa. Essentially, it reverses the result: if 'a' is True, 'NOT a' is False.</li>
            </ul>

            <p className="lesson-p">Let's see some examples:</p>
            <pre style={style.codeBlock}>
                a = True<br/>
                b = False<br/>
                print(a and b)  # Logical AND<br/>
                # Output: False (because b is False)<br/><br/>
                print(a or b)   # Logical OR<br/>
                # Output: True (because at least one, a, is True)<br/><br/>
                print(not a)    # Logical NOT<br/>
                # Output: False (because NOT reverses True to False)
            </pre>

            <h3 className="lesson-h3">User Input</h3>
            <p className="lesson-p">In Python, you can gather input from the user using the <code style={style.code}>input()</code> function. This function reads a line from the input, converts it to a string, and returns it. You can prompt the user for input by passing a string to <code style={style.code}>input()</code>, which will be displayed as a message before the user's input.</p>
            <pre style={style.codeBlock}>
                name = input("Enter your name: ")<br/>
                print("Hello, " + name)<br/>
                # The output will vary based on the user's input
            </pre>

            <p className="lesson-p">Note that the <code style={style.code}>input()</code> function always returns a string. If you expect a numerical value, you'll need to convert the input to an integer or a float.</p>

            <h3 className="lesson-h3">Type Checking and Type Conversion</h3>
            <p className="lesson-p">In Python, understanding the data type of a variable is crucial for effective programming. The <code style={style.code}>type()</code> function is a built-in function that helps you determine the data type of any variable. This function is particularly useful when you are unsure about the kind of data you are dealing with, or when you receive data from external sources, like user inputs or files. Knowing the data type helps in deciding what operations can be performed on the value stored in the variable.</p>
            <pre style={style.codeBlock}>
                number = 10<br/>
                print(type(number))  # Output: &lt;class 'int'&gt;<br/><br/>

                text = "Hello"<br/>
                print(type(text))  # Output: &lt;class 'str'&gt;
            </pre>

            <p className="lesson-p">Type casting, or type conversion, is the process of changing an entity of one data type into another. This is a powerful feature in Python that allows for the manipulation of data types to fit the needs of various operations. Here are some common examples of type conversion:</p>
            <pre style={style.codeBlock}>
                # Converting string to integer<br/>
                num_str = "123"<br/>
                num_int = int(num_str)<br/>
                print(num_int)  # Output: 123<br/><br/>

                # Converting integer to float<br/>
                num_float = float(num_int)<br/>
                print(num_float)  # Output: 123.0<br/><br/>

                # Converting float to string<br/>
                float_str = str(num_float)<br/>
                print(float_str)  # Output: '123.0'<br/><br/>

                # Converting integer to string<br/>
                int_str = str(num_int)<br/>
                print(int_str)  # Output: '123'
            </pre>

            <p className="lesson-p">It's important to be aware of type conversion implications in terms of data loss. For instance, converting a float to an integer will result in the loss of the fractional part, which might not be desirable in all cases.</p>
                        
            <h3 className="lesson-h3">Understanding Data Types in Print Statements and Concatenation</h3>
<p className="lesson-p">When using the <code style={style.code}>print()</code> function and concatenating strings, the data type of the variables involved is crucial. The <code style={style.code}>print()</code> function can handle different data types without issue, as it converts them to strings for display. However, when concatenating strings with other data types manually, you must ensure all elements are of the same type, typically strings. Otherwise, Python will raise a TypeError. To concatenate different data types, you can convert non-string types to strings using the <code style={style.code}>str()</code> function.</p>
<pre style={style.codeBlock}>
    age = 30<br/>
    # The following line will raise an error<br/>
    # print("You are " + age + " years old")  # TypeError<br/><br/>

    # Correct way: convert integer to string<br/>
    print("You are " + str(age) + " years old")  # Output: You are 30 years old<br/><br/>

    # Using print() with comma separation automatically handles different data types<br/>
    print("You are", age, "years old")  # Output: You are 30 years old
</pre>

<p className="lesson-p">In addition, the <code style={style.code}>print()</code> function has an optional parameter called <code style={style.code}>end</code>, which defines what is printed at the end of the output. By default, <code style={style.code}>print()</code> ends with a newline character (<code style={style.code}>'\n'</code>), meaning each print statement outputs to a new line. However, you can change this behavior by specifying a different string for <code style={style.code}>end</code>. For example, using <code style={style.code}>end=' '</code> will space-separate the outputs on the same line.</p>
<pre style={style.codeBlock}>
    # Using print() with the end parameter<br/>
    print("Hello", end=' ')<br/>
    print("World")<br/>
    # Output: Hello World
</pre>

<p className="lesson-p">This feature is particularly useful when you need to print multiple items on the same line, separated by a character other than the newline character.</p>

<p className="lesson-p">When using commas in the <code style={style.code}>print()</code> function, Python automatically inserts a space between each item and converts non-string data types to strings. This feature makes it easier to print mixed data types without explicit type conversion, but it also means you have less control over formatting compared to manual string concatenation.</p>
             
            
<h3 className="lesson-h3">Formatted String Literals (F-Strings)</h3>
<p className="lesson-p">Formatted string literals, or f-strings, provide a more readable and concise way to format strings. They allow you to embed expressions inside string literals using curly braces. This makes it easier to incorporate variables and expressions into strings without complex concatenation or using the `str.format()` method. F-strings are evaluated at runtime, which means that any valid Python expressions can be embedded directly within the string.</p>

<p className="lesson-p">F-strings enhance readability and reduce errors in string formatting, especially when dealing with multiple variables or complex expressions. They are a significant improvement over the older formatting methods in Python, offering both simplicity and flexibility.</p>

<pre style={style.codeBlock}>
    {`# Example 1: Basic use of f-strings
name = "Alice"
age = 30
greeting = f"Hello, {name}. You are {age} years old."
print(greeting)
# Output: Hello, Alice. You are 30 years old.

# Example 2: Using expressions inside f-strings
hours = 8
rate = 15
total = f"Total earnings: {hours * rate}"
print(total)
# Output: Total earnings: 120

# Example 3: Combining text and variables
temperature = 22.5
weather = f"The current temperature is {temperature}°C."
print(weather)
# Output: The current temperature is 22.5°C.`}
</pre>

<p> className="lesson-p"These examples demonstrate how f-strings can be used in various scenarios to simplify the process of string formatting. They are particularly useful in dynamically generating strings that include variables and expressions.</p>




            <h3 className="lesson-h3">Using the len() Function with Strings</h3>
            <p className="lesson-p">The <code style={style.code}>len()</code> feature in Python is commonly used to determine the length of a string, which is essentially the number of characters it contains. This is particularly useful for a variety of tasks involving strings, such as determining the size of a message or processing individual characters in the string. Mastering the use of <code style={style.code}>len()</code> with strings is a basic and essential aspect of Python programming.</p>

            <p className="lesson-p">Here's an example of how to use <code style={style.code}>len()</code> with a string:</p>
            <pre style={style.codeBlock}>
                # Determining the length of a string
                message = "Hello, World!"<br/>
                print(len(message))  # Output: 13
                # This shows the number of characters in the string "Hello, World!"
            </pre>

            <p className="lesson-p">Understanding how to find the length of a string is a crucial step before diving into more complex operations with strings and, later on, other data structures like lists and tuples.</p>

            <h2 className="lesson-h2">Practice Exercises</h2>
            <p className="lesson-p">Apply the concepts you've learned in this module with these additional exercises.</p>
            <ol>
                <li>
                    Calculate the total length of two strings, "Python" and "Programming", and print the result.
                    <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution1 && <pre style={style.codeBlock}>
                        # Solution<br/>
                        string1 = "Python"<br/>
                        string2 = "Programming"<br/>
                        total_length = len(string1) + len(string2)<br/>
                        print(total_length)<br/>
                        # Output: 18
                    </pre>}
                </li>
                <li>
                    Create a Python script to concatenate the string 'Data' with the string representation of the integer 2024 and then print the result.
                    <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution2 && <pre style={style.codeBlock}>
                        # Solution<br/>
                        year = 2024<br/>
                        print('Data' + str(year))<br/>
                        # Output: Data2024
                    </pre>}
                </li>
                <li>
                    Determine and print the length of the string "Learning Python".
                    <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution3 && <pre style={style.codeBlock}>
                        # Solution<br/>
                        message = "Learning Python"<br/>
                        print(len(message))<br/>
                        # Output: 15
                    </pre>}
                </li>
                <li>
                    Write a script to check if the number 8 is greater than 5 and less than 10. Print the result.
                    <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution4 && (
                        <pre style={style.codeBlock}>
                            {'# Solution\nprint(5 < 8 < 10)\n# Output: True'}
                        </pre>
                    )}
                </li>
                <li>
                    Create a script that calculates the modulus of 15 divided by 4 and prints the remainder.
                    <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution5 && <pre style={style.codeBlock}>
                        # Solution<br/>
                        print(15 % 4)<br/>
                        # Output: 3
                    </pre>}
                </li>
            </ol>

            <h2 className="lesson-h2">Recap of Module 2 and Preview of Module 3</h2>
            <p className="lesson-p">Through this module, you've been introduced to the basic data types and operations in Python. These are the foundational elements upon which more complex Python programs are built. Understanding and mastering these will greatly aid your journey in Python programming.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module2Lesson;
