// SubscriptionManagement.js
import React, { useContext, useEffect, useState } from 'react';
//import { Gradient } from 'whatamesh';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import { fetchAndUpdateUserData } from '../utils/userService';
import { format } from 'date-fns';

import './SubscriptionManagement.css';

const SubscriptionManagement = () => {
    /* works perfect for cancelation feature
    const { user, login } = useContext(AuthContext);
    const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const [isPendingCancellation, setIsPendingCancellation] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [accessEndDate, setAccessEndDate] = useState('');*/

    const { user } = useContext(AuthContext);
    const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const [accessEndDate, setAccessEndDate] = useState('');
    

useEffect(() => {
    if (user && user.stripeSubscriptionId) {
    //axios.get(`http://localhost:4000/api/subscription/${user.googleId}`)
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/subscription/${user.googleId}`)
            .then(response => {
                console.log('Subscription Info:', response.data);
                setSubscriptionInfo(response.data);
                setAccessEndDate(response.data.accessEndDate);
                //setIsPendingCancellation(response.data.isPendingCancellation);
                /*
                if (response.data.isPendingCancellation) {
                    setAccessEndDate(response.data.accessEndDate);
                }*/
            })
            .catch(error => console.error('Error fetching subscription details:', error));
    }
}, [user]);
    
    /* works perfect but this is for cancelation feature
    const toggleCancelConfirmation = () => {
        setShowCancelConfirmation(prev => !prev); // Toggles the visibility of the confirmation dialog
    };

        useEffect(() => {
        if (showCancelConfirmation) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showCancelConfirmation]);

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('cancel-confirmation-overlay')) {
            toggleCancelConfirmation();
        }
    };

    const handleCancelSubscription = async () => {
        console.log('Cancel button clicked');
        // Implement the API call to cancel the subscription
        axios.post(`http://localhost:4000/api/cancel-subscription/${user.googleId}`)
            .then(response => {
                // Handle successful cancellation
                console.log('Subscription cancelled:', response.data);
                // Call fetchAndUpdateUserData to update user data in frontend
                fetchAndUpdateUserData(user.googleId, login);
            })
            .catch(error => console.error('Error cancelling subscription:', error));
    };

    const handleCancelSubscription = async () => {
    if (showCancelConfirmation) {
        // User has confirmed the cancellation, proceed with API call
        try {
            //const response = await axios.post(`http://localhost:4000/api/cancel-subscription/${user.googleId}`);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cancel-subscription/${user.googleId}`);
            console.log('Subscription cancelled:', response.data);
            
            // Optionally, fetch and update user data again
            fetchAndUpdateUserData(user.googleId, login);

            // Close the confirmation dialog
            setShowCancelConfirmation(false);

            // Update any other states or perform additional actions as needed
            // For example, update subscription info or show a success message
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            // Handle errors, such as displaying a notification to the user
        }
    } else {
        // Show the confirmation dialog
        setShowCancelConfirmation(true);
    }
};*/


    // Redirect logic
    if (!user || !user.stripeSubscriptionId) {
        window.location.href = '/pricing';
        return null; // This prevents the rest of the component from rendering
    }
    console.log('info', subscriptionInfo);
    

/* below is perfect working code its just including the cancel feature
    const handleAbortCancellation = async () => {
    try {
        //const response = await axios.post(`http://localhost:4000/api/abort-cancel-subscription/${user.googleId}`);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/abort-cancel-subscription/${user.googleId}`);
        console.log('Cancellation aborted:', response.data);
        // Optionally, fetch and update user data again
        fetchAndUpdateUserData(user.googleId, login);
    } catch (error) {
        console.error('Error aborting cancellation:', error);
    }
    };


    return (
        <div className="view-subscription-container">
            {showCancelConfirmation && <div className="cancel-confirmation-overlay"></div>}
            
            <div className="view-subscription-content">
                <div className="subscription-card">
                    <h2 className="view-subscription-header-text">Your Subscription Plan:</h2>
                    {subscriptionInfo && (
                        <div>
                            <h3 className="view-subscription-subheader-text">
                                {`${subscriptionInfo.planName} - $${subscriptionInfo.price} / ${subscriptionInfo.billingCycle}`}
                            </h3>
                            {isPendingCancellation ? (
                                <>
                                    <p>Subscription Active Until: {format(new Date(accessEndDate), 'PPP')}</p>
                                    <button onClick={handleAbortCancellation} className="undo-cancellation-button">
                                        Undo Cancellation
                                    </button>
                                </>
                            ) : (
                                <>
                                    <p>Next Renewal Date: {format(new Date(subscriptionInfo.accessEndDate), 'PPP')}</p>
                                    <button onClick={handleCancelSubscription} className="cancel-subscription-button">
                                        Cancel Subscription
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {showCancelConfirmation && (
                <div className="confirmation-dialog">
                    <p className="confirmation-dialog-text">Are you sure you want to cancel your subscription?</p>
                    <button onClick={handleCancelSubscription} className="dialog-confirm-button">
                        Yes, Cancel
                    </button>
                    <button onClick={toggleCancelConfirmation} className="dialog-cancel-button">
                        No, Go Back
                    </button>
                </div>
            )}
        </div>
    );*/

    return (
        <div className="view-subscription-container">
            <div className="view-subscription-content">
                <div className="subscription-card">
                    <h2 className="view-subscription-header-text">Your Access Details:</h2>
                    {subscriptionInfo && (
                        <div>
                            <h3 className="view-subscription-subheader-text">
                                Learn to Code
                            </h3>
                            <p>Access Granted Until: {format(new Date(accessEndDate), 'PPP')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};

export default SubscriptionManagement;
