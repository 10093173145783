import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
import { Gradient } from 'whatamesh';
import HomeSVG from '../assets/homesvg.svg';
import HomeSVG1 from '../assets/homesvg1.svg';
import HomeSVG2 from '../assets/homesvg2.svg';
import HomeSVG3 from '../assets/homesvg3.svg';
import HomeSVG4 from '../assets/homesvg4.svg';
import HomeSVG5 from '../assets/homesvg5.svg';
import HomeSVG6 from '../assets/homesvg6.svg';
import { AuthContext } from './AuthContext';

const Header = () => {
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient("#gradient-canvas");
    });

    return (
        <div>
        <canvas id ="gradient-canvas"></canvas>
            <div className="header-container">
                <h1>Unlock Your Coding Potential</h1>
                {/*<p><br />A comprehensive learn-to-code platform offering a wealth of resources, including in-depth lessons and a variety of coding questions to build your programming skills.</p>*/}
                <p><br />Your fastest path to becoming a programmer: A comprehensive learn-to-code platform equipped with everything you need to master coding.</p>
                <button className="globe-header-button">
                    {user && user.stripeSubscriptionId ? (
                        <span className="globe-header-button-span"><a className="globe-header-button-text" href="/dashboard">Dashboard</a></span>
                    ) : (
                        <span className="globe-header-button-span"><a className="globe-header-button-text" href="/pricing">Buy Now</a></span>
                    )}
                </button>
            </div>
            <img src={HomeSVG5} className="svg-header-image-home" />
        </div>
    )
}

export default Header;
