import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module10Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 10: Modules and Packages in Python</h1>

            <h2 className="lesson-h2">Understanding Modules and Packages</h2>
            <p className="lesson-p">Modules and packages are fundamental concepts in Python, enabling the organization and reuse of code. A module is a single Python file containing definitions and statements, while a package is a collection of modules in a directory structure.</p>

            <h3 className="lesson-h3">Using Python Modules</h3>
            <p className="lesson-p">Modules in Python help to keep functions, variables, and classes organized by relevant functionality. They can be easily imported and reused in other parts of your program, enhancing code modularity and reusability. By using modules, developers can avoid code duplication and make their codebase more manageable and scalable. Importing standard or third-party modules also allows leveraging a wide range of functionalities without writing extensive code.</p>
<pre style={style.codeBlock}>
    {`# Importing a module
import math
print(math.sqrt(16))
# Output: 4.0

# Importing specific functions
from math import sqrt
print(sqrt(16))
# Output: 4.0

# Importing from the random module
import random
print(random.randint(1, 10))
# Output: [Random number between 1 and 10]`}
</pre>

            <h3 className="lesson-h3">Creating Your Own Modules</h3>
            <p className="lesson-p">Creating your own modules in Python can be done by writing Python code in a file and then importing it using the module's filename. This process enables the encapsulation of related functions and classes, making them easily accessible across different parts of a project. Custom modules are a powerful tool for organizing your code logically, facilitating easier maintenance and understanding, especially in larger projects where code organization is critical.</p>
            <pre style={style.codeBlock}>
                {`# Example: Creating a simple module
# File: my_module.py
def greet(name):
    return f"Hello, {name}!"

# Importing and using the custom module
from my_module import greet
print(greet('Alice'))
# Output: Hello, Alice!`}
            </pre>

            <h3 className="lesson-h3">Understanding Python Packages</h3>
            <p className="lesson-p">Packages are a way of structuring Python's module namespace using "dotted module names". A package can contain multiple modules or subpackages, allowing for a hierarchical structuring of the codebase. This hierarchy is crucial for organizing large codebases into logical units, improving code readability and maintainability. Packages also enable namespace management, preventing conflicts in function names and variables across different modules.</p>
            <pre style={style.codeBlock}>
                {`# Example: Importing from a package
# Assume a package 'mypackage' with a module 'mymodule'
from mypackage import mymodule
mymodule.my_function()`}
            </pre>

<h2 className="lesson-h2">Practice Exercises</h2>
<p>Apply your understanding of Python modules and packages with these straightforward exercises.</p>
<ol>
    <li>
        Import the 'random' module and use its `randint` function to generate a random number between 1 and 10. Print the result.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
import random
print(random.randint(1, 10))
# Output: [Random number between 1 and 10]`}
        </pre>}
    </li>
    <li>
        Create a module named 'greetings' that contains two functions: `say_hello()` and `say_goodbye()`, both accepting a name as an argument. Import and call these functions in another script, passing a name of your choice.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
# greetings.py
def say_hello(name):
    return f"Hello, {name}!"

def say_goodbye(name):
    return f"Goodbye, {name}!"

# In another script
from greetings import say_hello, say_goodbye
print(say_hello('Alice'))
print(say_goodbye('Bob'))
# Output: Hello, Alice!
# Output: Goodbye, Bob!`}
        </pre>}
    </li>
    <li>
        Create a simple package named 'mathematics' with a single module 'arithmetic'. In 'arithmetic', define a function `subtract(a, b)` that returns the difference between two numbers. Then, use this function in a separate script.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
# mathematics/arithmetic.py
def subtract(a, b):
    return a - b

# In another script
from mathematics.arithmetic import subtract
print(subtract(10, 5))
# Output: 5`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 10 and Preview of Module 11</h2>
            <p className="lesson-p">Module 10 explored the organization and usage of modules and packages in Python, emphasizing their role in creating scalable and maintainable codebases.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module10Lesson;
