import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module7Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 7: Basic File Handling in Python</h1>

            <h2 className="lesson-h2">Introduction to File Handling</h2>
            <p className="lesson-p">File handling is an essential aspect of Python programming, allowing for the reading and writing of files. This module will cover the basic operations such as opening, reading, writing, and closing files in Python.</p>

            <h3 className="lesson-h3">Opening and Closing Files</h3>
            <p className="lesson-p">Files in Python can be opened using the built-in <code>open()</code> function, which returns a file object. It's important to close the file properly after its operations are complete to free up system resources.</p>
            <pre style={style.codeBlock}>
                {`# Opening a file
file = open('example.txt', 'r')  # 'r' stands for read mode
# Always ensure to close the file
file.close()`}
            </pre>

            <h3 className="lesson-h3">Reading from a File</h3>
            <p className="lesson-p">Once a file is opened in reading mode, you can read its content using methods like <code>read()</code>, <code>readline()</code>, or <code>readlines()</code>.</p>
            <pre style={style.codeBlock}>
                {`# Reading the entire content of a file
file = open('example.txt', 'r')
content = file.read()
print(content)
file.close()

# Reading a file line by line
file = open('example.txt', 'r')
for line in file:
    print(line, end='')
file.close()`}
            </pre>

            <h3 className="lesson-h3">Writing to a File</h3>
            <p className="lesson-p">To write to a file, it must be opened in write ('w') or append ('a') mode. The <code>write()</code> method is used to write data to the file.</p>
            <pre style={style.codeBlock}>
                {`# Writing to a file
file = open('newfile.txt', 'w')
file.write('Hello, Python!')
file.close()

# Appending to a file
file = open('newfile.txt', 'a')
file.write('\\nAdding a new line.')
file.close()`}
            </pre>

            <h3 className="lesson-h3">Using 'with' Statement for File Operations</h3>
            <p className="lesson-p">The 'with' statement in Python ensures proper acquisition and release of resources. Using 'with' for file operations simplifies code and automatically handles file closing.</p>
            <pre style={style.codeBlock}>
                {`# Using with statement for reading
with open('example.txt', 'r') as file:
    content = file.read()
    print(content)

# Using with statement for writing
with open('newfile.txt', 'w') as file:
    file.write('Using with statement.')`}
            </pre>

            <h2 className="lesson-h2">Practice Exercises</h2>
            <p className="lesson-p">Apply the concepts you've learned in this module with these exercises.</p>
            <ol>
                <li>
                    Write a Python script to read a file called 'data.txt' and print its contents.
                    <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution1 && <pre style={style.codeBlock}>
                        {`# Solution
with open('data.txt', 'r') as file:
    content = file.read()
    print(content)
# Output will vary based on the contents of 'data.txt'`}
                    </pre>}
                </li>
                <li>
                    Create a Python script to write the text 'Hello, File Handling!' to a new file named 'output.txt'.
                    <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution2 && <pre style={style.codeBlock}>
                        {`# Solution
with open('output.txt', 'w') as file:
    file.write('Hello, File Handling!')
# Check 'output.txt' for the written content`}
                    </pre>}
                </li>
                <li>
                    Write a script to append the line 'This is a new line.' to an existing file 'append.txt'.
                    <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
                    {showSolution3 && <pre style={style.codeBlock}>
                        {`# Solution
with open('append.txt', 'a') as file:
    file.write('\\nThis is a new line.')
# 'append.txt' will have the new line appended`}
                    </pre>}
                </li>
            </ol>

            <h2 className="lesson-h2">Recap of Module 7 and Preview of Module 8</h2>
            <p className="lesson-p">This module provided an introduction to basic file handling in Python, covering how to read from and write to files, along with best practices for managing file resources effectively.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module7Lesson;
