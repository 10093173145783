import React, { useState, useEffect, createContext } from 'react';
import { fetchAndUpdateUserData } from '../utils/userService';
//import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext(null);


export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
    });
    
      useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      const user = JSON.parse(savedUser);
      fetchAndUpdateUserData(user.googleId, login);
    }
  }, []); // Runs once when the provider mounts

    //const [token, setToken] = useState(localStorage.getItem('token'));

    //console.log("AuthProvider Rendered: User -", user, "Token -", token);

    // Function to check if the token is expired
    /*
    const isTokenExpired = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // in seconds
            return decodedToken.exp < currentTime;
        } catch (error) {
            return true; // If error in decoding, assume expired
        }
    };*/
/*
    const login = (userData, authToken) => {
        if (isTokenExpired(authToken)) {
            console.error("Token is expired. Login failed.");
            return;
        }
        console.log("Login Function Called: UserData -", userData, "AuthToken -", authToken);
        setUser(userData);
        setToken(authToken);
        localStorage.setItem('token', authToken);
    };*/

    const login = (userData) => {
        console.log("Login Function Called: UserData -", userData);
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };    

    const logout = () => {
        console.log("Logout Function Called");
        setUser(null);
        //setToken(null);
        localStorage.removeItem('user');
        //localStorage.removeItem('token');
    };


    return (
        /*
        <AuthContext.Provider value={{ user, token, login, logout }}>
            {children}
        </AuthContext.Provider>*/

        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>

    );
};
