/*import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

function QuizComponent() {
    const { user, token } = useContext(AuthContext);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  

const handleSubmitQuiz = async () => {
  try {
    const response = await axios.post('http://localhost:4000/api/submit-quiz', {
      userId: user._id,
      answers: userAnswers // Send the user's answers
    });

    setQuizSubmitted(true);
    const bestScore = response.data.bestScore;
    // Display the best score
    // ...
  } catch (error) {
    console.error('Error submitting quiz:', error);
  }
};




        useEffect(() => {
        if (!token) {
            console.error("No token found. User not logged in.");
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.get('http://localhost:4000/api/questions', config)
            .then(response => setQuestions(response.data))
            .catch(error => console.error('Error fetching questions:', error));
        }, [token]);
  
  
  useEffect(() => {
  const fetchUserQuizData = async () => {
    try {
      const response = await axios.get(`http://localhost:4000/api/user/${user._id}/quizzes`);
      // Process and display the data
    } catch (error) {
      console.error('Error fetching user quiz data:', error);
    }
  };

  if (user) {
    fetchUserQuizData();
  }
}, [user]);



const handleAnswerClick = async (answerText) => {
  const newUserAnswers = {
    ...userAnswers,
    [currentQuestionIndex]: answerText
  };
  setUserAnswers(newUserAnswers);
};


  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentIndex => currentIndex + 1);
    }
  };

  const calculateScore = () => {
    const correctCount = questions.reduce((score, question, index) => {
      const correctAnswer = question.answers.find(answer => answer.isCorrect).text;
      return score + (userAnswers[index] === correctAnswer ? 1 : 0);
    }, 0);

    return Math.round((correctCount / questions.length) * 100); // Calculate percentage and round
  };

  if (questions.length === 0) return <div>Loading questions...</div>;

  if (quizSubmitted) {
    const scorePercentage = calculateScore();
    return (
      <div>
        <h2>Your Quiz Results</h2>
        <p>Your score: {scorePercentage}%</p>
      </div>
    );
  }

    const currentQuestion = questions[currentQuestionIndex];
    const selectedAnswer = userAnswers[currentQuestionIndex];
    const isAnswerSelected = selectedAnswer !== undefined;

  return (
    <div>
      <h2>{currentQuestion.questionText}</h2>
      {currentQuestion.answers.map((answer, index) => (
        <button 
          key={index} 
          onClick={() => handleAnswerClick(answer.text)}
          style={{ 
            backgroundColor: selectedAnswer === answer.text ? '#4CAF50' : '', 
            color: selectedAnswer === answer.text ? 'white' : ''
          }}>
          {answer.text}
        </button>
      ))}
      <button 
        onClick={handleNextQuestion}
        disabled={!isAnswerSelected}
      >
        {currentQuestionIndex === questions.length - 1 ? 'Submit Quiz' : 'Next Question'}
      </button>

                  {currentQuestionIndex === questions.length - 1 && (
                <button onClick={handleSubmitQuiz}>
                    Submit Quiz
                </button>
            )}
    </div>
  );
}

export default QuizComponent;
*/



import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './QuizComponent.css';

function QuizComponent() {
    const { user } = useContext(AuthContext);
    const [quiz, setQuiz] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [isAnswerSelected, setIsAnswerSelected] = useState(false);
    const [quizSubmitted, setQuizSubmitted] = useState(false);
    const [scorePercentage, setScorePercentage] = useState(0);

    const { quizTitle } = useParams();

    useEffect(() => {
        if (!user) {
            console.error("User not logged in.");
            return;
        }

        const fetchQuiz = async () => {
            const decodedTitle = quizTitle.replace(/-/g, ' ');
            try {
              //const response = await axios.get(`http://localhost:4000/api/quiz-by-title/${decodedTitle}`);
              const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/quiz-by-title/${decodedTitle}`);
                setQuiz(response.data);
            } catch (error) {
                console.error('Error fetching quiz:', error);
            }
        };

        fetchQuiz();
    }, [quizTitle, user]);
  /*
      const calculateScore = () => {
        const correctAnswers = quiz.questions.reduce((acc, question, index) => {
            const correctAnswer = question.answers.find(answer => answer.isCorrect).text;
            return acc + (userAnswers[index] === correctAnswer ? 1 : 0);
        }, 0);

        return Math.round((correctAnswers / quiz.questions.length) * 100);
    };*/

    const handleAnswerClick = (answerText) => {
        setUserAnswers({
            ...userAnswers,
            [currentQuestionIndex]: answerText
        });
        setIsAnswerSelected(true);
    };

const handleNextQuestion = () => {
    if (isAnswerSelected && currentQuestionIndex < quiz.questions.length - 1) {
        setCurrentQuestionIndex(currentIndex => currentIndex + 1);
        setIsAnswerSelected(false);
    } else if (isAnswerSelected) {
        handleSubmitQuiz(); // Call the submit function here
    }
};

  /* works fine
  const handleSubmitQuiz = async () => {
    try {
      const score = calculateScore();
        await axios.post('http://localhost:4000/api/update-quiz-progress', {
            userId: user._id,
            quizId: quiz._id,
            score
        });

        setQuizSubmitted(true);
        setScorePercentage(score);
    } catch (error) {
        console.error('Error updating quiz progress:', error);
    }
  };*/

  const handleSubmitQuiz = async () => {
  try {
    // Prepare the user's answers to be sent to the backend
    const userResponses = quiz.questions.map((question, index) => {
      return userAnswers[index] || ''; // Send the selected answer or an empty string if no answer was selected
    });

    // Send the user's answers to the backend
    //const response = await axios.post('http://localhost:4000/api/submit-quiz', {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/submit-quiz`, {
      userId: user._id, // or however you're getting the userId
      quizId: quiz._id,
      answers: userResponses
    });

    // The backend returns the calculated score
    const score = response.data.score;

    setQuizSubmitted(true);
    setScorePercentage(score);
  } catch (error) {
    console.error('Error submitting quiz:', error);
  }
};

  
function formatPythonCode(codeString) {
    // Replace "\\n" with "\n" for new lines
    const formattedString = codeString.replace(/\\n/g, '\n');
    return <pre>{formattedString}</pre>;
}







/*
    if (!quiz) return <div>Loading quiz...</div>;

    if (quizSubmitted) {
        return (
            <div>
                <h2>Your Quiz Results</h2>
                <p>Your score: {scorePercentage}%</p>
            </div>
        );
    }

    const currentQuestion = quiz.questions[currentQuestionIndex];
    const selectedAnswer = userAnswers[currentQuestionIndex];

    return (
        <div>
            <h2>{quiz.title}</h2>
            <div>
                <p>{currentQuestion.questionText}</p>
                {currentQuestion.answers.map((answer, index) => (
                    <button
                        key={index}
                        onClick={() => handleAnswerClick(answer.text)}
                        style={{ 
                            backgroundColor: selectedAnswer === answer.text ? '#4CAF50' : '', 
                            color: selectedAnswer === answer.text ? 'white' : '' 
                        }}
                    >
                        {answer.text}
                    </button>
                ))}
                <button onClick={handleNextQuestion} disabled={!isAnswerSelected}>
                    {currentQuestionIndex === quiz.questions.length - 1 ? 'Submit Quiz' : 'Next Question'}
                </button>
            </div>
        </div>
    );
}*/
  
    if (!quiz) {
      return (
          <div className="quiz-container">
              <h2 className="quiz-header"></h2>
          </div>
    );
}

// Continue with the rest of your component...


    if (quizSubmitted) {
        return (
          <div className="quiz-container">
              <div className="quiz-navigation">
                <Link to="/dashboard" className="quiz-back-button">Back</Link>
                <Link to="/" className="quiz-home-button">reventt</Link>
              </div>
              <h1 className="quiz-header">Your Quiz Results</h1>
            <h2 className="quiz-score">Your score: {scorePercentage}%</h2>
            <Link to="/dashboard" className="quiz-back-button quiz-back-to-dashboard-button">Return to Dashboard</Link>
            </div>
        );
    }

    const currentQuestion = quiz.questions[currentQuestionIndex];
    const selectedAnswer = userAnswers[currentQuestionIndex];
/* works fine (this is the version without the \n in the question)
  return (
    <div className="quiz-container">
          <div className="quiz-navigation">
            <Link to="/dashboard" className="quiz-back-button">Back</Link>
            <Link to="/" className="quiz-home-button">reventt</Link>
          </div>
          <p className="quiz-progress">Question {currentQuestionIndex + 1}/{quiz.questions.length}</p>
          <h2 className="quiz-header">{quiz.title}</h2>
          <div className="quiz-content">
              <div className="quiz-question">
                  <p>{currentQuestion.questionText}</p>
                  {currentQuestion.answers.map((answer, index) => (
                      <button
                          key={index}
                          onClick={() => handleAnswerClick(answer.text)}
                          className={`quiz-answer-button ${selectedAnswer === answer.text ? 'selected' : ''}`}
                      >
                          {answer.text}
                      </button>
                  ))}
              </div>
              <div className="button-container">
                  <button 
                      onClick={handleNextQuestion} 
                      disabled={!isAnswerSelected}
                      className="quiz-submit-button"
                  >
                      {currentQuestionIndex === quiz.questions.length - 1 ? 'Submit Quiz' : 'Next Question'}
                  </button>
              </div>
          </div>
      </div>
  );
*/

return (
    <div className="quiz-container">
        <div className="quiz-navigation">
            <Link to="/dashboard" className="quiz-back-button">Back</Link>
            <Link to="/" className="quiz-home-button">reventt</Link>
        </div>
        <h3 className="quiz-progress">Question {currentQuestionIndex + 1}/{quiz.questions.length}</h3>
        <h1 className="quiz-header">{quiz.title} Quiz</h1>
        <div className="quiz-content">
<div className="quiz-question">
        {/* Format and display the question text */}
        <div className="quiz-question-text">
          {formatPythonCode(currentQuestion.questionText)}
          </div>
    {currentQuestion.answers.map((answer, index) => (
        <button
            key={index}
            onClick={() => handleAnswerClick(answer.text)}
            className={`quiz-answer-button ${selectedAnswer === answer.text ? 'selected' : ''}`}
        >
            {answer.text}
        </button>
    ))}
</div>

            <div className="button-container">
                <button 
                    onClick={handleNextQuestion} 
                    disabled={!isAnswerSelected}
                    className="quiz-submit-button"
                >
                    {currentQuestionIndex === quiz.questions.length - 1 ? 'Submit Quiz' : 'Next Question'}
                </button>
            </div>
        </div>
    </div>
);



}

export default QuizComponent;
