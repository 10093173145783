// utils/userService.js
import axios from 'axios';

export const fetchAndUpdateUserData = async (googleId, login) => {
  try {
    //const response = await axios.get(`http://localhost:4000/api/user/${googleId}`);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/${googleId}`);
    const updatedUserData = response.data;
    console.log("Fetched User Data:", updatedUserData); // Log fetched data

    // Update user data in local storage
    localStorage.setItem('user', JSON.stringify(updatedUserData));

    // Update user data in AuthContext
    login(updatedUserData);
  } catch (error) {
    console.error('Error fetching and updating user data:', error);
  }
};
