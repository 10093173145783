import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module1Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

/*
    const style = {
        container: {
            lineHeight: '1.6',
        },
        code: {
            backgroundColor: '#f4f4f4',
            padding: '2px 4px',
            color: '#b22222',
        },
        codeBlock: {
            backgroundColor: '#f4f4f4',
            padding: '10px',
            color: '#b22222',
            whiteSpace: 'pre',
        },
        link: {
            color: '#1a0dab',
        },
    };*/

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};


    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 1: Introduction to Python</h1>

            <h2 className="lesson-h2">Overview of Python</h2>
            <p className="lesson-p">Python is a dynamic, high-level, and versatile programming language that emphasizes readability and efficiency. Since its creation by Guido van Rossum in the early 1990s, Python has grown in popularity across various fields, such as web development, data analysis, scientific computing, and more.</p>

            <h3 className="lesson-h3">Key Features</h3>
            <ul>
                <li><strong>Simple and Readable Syntax:</strong> Python's syntax is designed to be intuitive and mirrors the English language, making it an excellent language for beginners.</li>
                <li><strong>Versatile and Powerful:</strong> From simple automation scripts to complex machine learning algorithms, Python's capabilities are vast.</li>
                <li><strong>Large Community and Rich Ecosystem:</strong> A robust community contributes to a vast selection of libraries and frameworks, making Python suitable for many tasks.</li>
                <li><strong>Cross-Platform Compatibility:</strong> Python runs on Windows, macOS, Linux, and even mobile platforms.</li>
            </ul>

            <h2 className="lesson-h2">Why Python is a Great Choice</h2>
            <p className="lesson-p">Python's popularity isn't just due to its easy-to-understand syntax. It's also because of its versatility, allowing you to embark on different paths like web development with frameworks like Django and Flask, or data science with libraries like Pandas and NumPy.</p>

            <h2 className="lesson-h2">Installation Guide</h2>
            <p className="lesson-p">If you prefer not to install Python on your computer, you can use an online Python compiler to write and run your code. These compilers are web-based tools that allow you to program in Python directly from your browser. Some popular online Python compilers include Repl.it, PythonAnywhere, and Trinket. These platforms are user-friendly and often come with additional features like code sharing and collaboration tools.</p>

            <h3 className="lesson-h3">Windows</h3>
            <ol>
                <li>Download the latest version from <a className="lesson-link" style={style.link} href="https://www.python.org/downloads/">python.org</a>.</li>
                <li>Run the installer. Ensure the "Add Python to PATH" checkbox is selected.</li>
                <li>Verify installation: Open Command Prompt and type <code style={style.code}>python --version</code>.</li>
            </ol>

            <h3 className="lesson-h3">macOS</h3>
            <ol>
                <li>Check existing installation: Open Terminal and enter <code style={style.code}>python3 --version</code>.</li>
                <li>If not installed or you need a different version, use Homebrew: <code style={style.code}>brew install python3</code>.</li>
            </ol>

            <h3 className="lesson-h3">Linux</h3>
            <ol>
                <li>Python usually comes pre-installed on Linux distributions.</li>
                <li>To install or update, use package managers like apt for Ubuntu/Debian: <code style={style.code}>sudo apt-get install python3</code>.</li>
            </ol>


            <h2 className="lesson-h2">Choosing a Development Environment</h2>
            <p className="lesson-p">For Python development, an IDE or a code editor enhances your coding experience with features like syntax highlighting, code completion, and debugging tools.</p>

            <h3 className="lesson-h3">Recommended IDEs and Editors</h3>
            <ul>
                <li><strong>PyCharm:</strong> Offers a complete set of tools for professional Python development.</li>
                <li><strong>VSCode:</strong> A lightweight but powerful editor, customizable with extensions for Python.</li>
            </ul>

            <h2 className="lesson-h2">Your First Python Script</h2>
            <h3 className="lesson-h3">"Hello, World!" Example</h3>
            <p className="lesson-p">If you are using a local Python installation:</p>
            <ol>
                <li>Create a file named <code style={style.code}>hello.py</code>.</li>
                <li>Write the following code:<br />
                    <code style={style.code}>print("Hello, World!")</code></li>
                <li>Run it in your terminal or command prompt with <code style={style.code}>python hello.py</code>.</li>
            </ol>

            <p className="lesson-p">If you are using an online Python compiler:</p>
            <ol>
                <li>Write the following code in the online editor:<br />
                    <code style={style.code}>print("Hello, World!")</code></li>
                <li>Simply click the run button provided by the online compiler to execute the code.</li>
            </ol>


            <h3 className="lesson-h3">Understanding the Code</h3>
            <p className="lesson-p"><code style={style.code}>print()</code> is a built-in Python function that outputs data to the screen.<br />
            The text <code style={style.code}>"Hello, World!"</code> is a string, a basic data type in Python.</p>

            <h2 className="lesson-h2">Python Syntax Deep Dive</h2>
            <h3 className="lesson-h3">Comments</h3>
            <p className="lesson-p">Comments are lines in the code that are not executed. They are used to leave notes and explanations to make the code easier to understand. In Python, there are two types of comments: single-line and multi-line comments.</p>

            <p className="lesson-p">Single-line comments start with <code style={style.code}>#</code> and extend to the end of the line:</p>
            <pre style={style.codeBlock}>
                # This is a single-line comment
            </pre>

            <p className="lesson-p">Multi-line comments can be created by using triple quotes (<code style={style.code}>'''</code> or <code style={style.code}>"""</code>), though they are technically multi-line strings that aren't assigned to any variable:</p>
            <pre style={style.codeBlock}>
                """
                This is a
                multi-line comment
                """
            </pre>

            <h3 className="lesson-h3">Variables and Data Types</h3>
            <p className="lesson-p">Variables are like containers that store data values. They allow you to label and store data in memory, which can then be used throughout your program. In Python, variables are created by assigning a value to them, and their data type is automatically determined based on the value assigned.</p>

            <pre style={style.codeBlock}>
                x = 10<br/>
                print(x)<br/>
                # Output: 10
            </pre>

            <p className="lesson-p">Python is dynamically typed, so you don't need to declare the variable type.</p>
            <p className="lesson-p">Common data types in Python include integers, floats, strings, and booleans. We'll explore these data types in more detail in the next module.</p>

            <h3 className="lesson-h3">Basic Operators</h3>
            <p className="lesson-p">Arithmetic operators:</p>
            <pre style={style.codeBlock}>
                # Addition<br/>
                print(5 + 3)
                # Output: 8<br/><br/>

                # Subtraction<br/>
                print(5 - 3)
                # Output: 2<br/><br/>

                # Multiplication<br/>
                print(5 * 3)
                # Output: 15<br/><br/>

                # Division<br/>
                print(5 / 3)
                # Output: 1.6666666666666667<br/><br/>

                # Modulo<br/>
                print(5 % 3)
                # Output: 2<br/><br/>

                # Exponentiation<br/>
                print(5 ** 3)
                # Output: 125
            </pre>

            <p className="lesson-p">Assignment operators:</p>
            <pre style={style.codeBlock}>
                y = 5<br/>
                y += 3  # Equivalent to y = y + 3<br/>
                print(y)
                # Output: 8
            </pre>

            <p className="lesson-p">Comparison operators:</p>
            <pre style={style.codeBlock}>
                a = 5<br/>
                b = 3<br/>
                print(a == b)  # Equal to<br/>
                # Output: False<br/><br/>
                print(a != b)  # Not equal to<br/>
                # Output: True<br/><br/>
                print(a &gt;= b)  # Greater than or equal to<br/>
                # Output: True<br/><br/>
                print(a &lt;= b)  # Less than or equal to<br/>
                # Output: False
            </pre>

<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Try these simple exercises to reinforce what you've learned:</p>
<ol>
    <li>
        Write a Python script that calculates the area of a rectangle with a width of 5 and a height of 3.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">
            {showSolution1 ? "Hide Solution" : "Show Solution"}
        </button>
        {showSolution1 && (
            <div>
                <pre style={style.codeBlock}>
                    # Solution<br/>
                    width = 5<br/>
                    height = 3<br/>
                    area = width * height<br/>
                    print(area)
                    # Output: 15
                </pre>
            </div>
        )}
    </li>
    <li>
        Create a Python script to calculate and print the result of 7 multiplied by 4.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">
            {showSolution2 ? "Hide Solution" : "Show Solution"}
        </button>
        {showSolution2 && (
            <div>
                <pre style={style.codeBlock}>
                    # Solution<br/>
                    result = 7 * 4<br/>
                    print(result)
                    # Output: 28
                </pre>
            </div>
        )}
    </li>
    <li>
        Write a Python script to print the square of the number 8.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">
            {showSolution3 ? "Hide Solution" : "Show Solution"}
        </button>
        {showSolution3 && (
            <div>
                <pre style={style.codeBlock}>
                    # Solution<br/>
                    number = 8<br/>
                    square = number ** 2<br/>
                    print(square)
                    # Output: 64
                </pre>
            </div>
        )}
        </li>
</ol>



            <h2 className="lesson-h2">Recap of Module 1 and Preview of Module 2</h2>
            <p className="lesson-p">You're now on your way to becoming a Python programmer! You've learned about Python's background, installed it, and written your first script.</p>
            <p className="lesson-p">In the next module, "Basic Concepts and Operations," we'll explore Python data types, basic operations, and start diving into Python's core concepts.</p>
            </div>

            <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module1Lesson;
