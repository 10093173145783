import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module11Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 11: Intermediate File Handling in Python</h1>

            <h2 className="lesson-h2">Handling Complex File Formats</h2>
            <p className="lesson-p">Expanding beyond basic text files, this module introduces handling more sophisticated file formats such as CSV, JSON, and binary files, which are prevalent in many applications.</p>

            <h3 className="lesson-h3">Working with CSV Files</h3>
            <p className="lesson-p">CSV (Comma-Separated Values) files are commonly used for storing tabular data. Python's `csv` module simplifies reading and writing CSV files, making it a go-to choice for tasks involving data export and import, such as working with databases or data analysis. This format is favored for its simplicity and wide compatibility with various tools and platforms.</p>
            <h3 className="lesson-h3">Common Methods for Handling CSV Files</h3>
<p className="lesson-p">
    <code>csv.reader(file)</code>: Reads data from a CSV file. <br />
    <code>csv.writer(file)</code>: Writes data to a CSV file. <br />
    <code>csv_writer.writerow(row)</code>: Writes a single row to the CSV file. <br />
    <code>csv_writer.writerows(rows)</code>: Writes multiple rows to the CSV file.
</p>
            <pre style={style.codeBlock}>
                {`# Reading a CSV file
import csv
with open('example.csv', mode='r') as file:
    csv_reader = csv.reader(file)
    for row in csv_reader:
        print(row)

# Writing to a CSV file
with open('output.csv', mode='w') as file:
    csv_writer = csv.writer(file)
    csv_writer.writerow(['Name', 'Age'])
    csv_writer.writerow(['Alice', 30])`}
            </pre>

            <h3 className="lesson-h3">Handling JSON Data</h3>
            <p className="lesson-p">JSON (JavaScript Object Notation) is a lightweight data interchange format. Python's `json` module allows easy handling of JSON data, which is prevalent in web APIs, configuration files, and NoSQL databases. Its human-readable format and compatibility with many programming languages make JSON an essential format for modern Python developers.</p>
            <h3 className="lesson-h3">Common Methods for Handling JSON Data</h3>
<p className="lesson-p">
    <code>json.loads(json_string)</code>: Parses a JSON formatted string. <br />
    <code>json.dumps(python_object)</code>: Converts Python object to JSON string. <br />
    <code>json.load(file)</code>: Reads JSON data from a file. <br />
    <code>json.dump(python_object, file)</code>: Writes Python object to a JSON file.
</p>
            <pre style={style.codeBlock}>
                {`# Parsing JSON from a string
import json
json_data = '{"name": "Alice", "age": 30}'
parsed_data = json.loads(json_data)
print(parsed_data['name'])

# Converting Python object to JSON
python_dict = {'name': 'Bob', 'age': 25}
json_string = json.dumps(python_dict)
print(json_string)`}
            </pre>

            <h3 className="lesson-h3">Binary File Operations</h3>
            <p className="lesson-p">Binary files store data in a format that is not human-readable. Python can read and write binary files, useful for images, audio files, etc. Handling binary files is crucial for applications like image processing, game development, and any scenario where raw data needs to be processed or transferred in its unaltered binary form.</p>
            <h3 className="lesson-h3">Common Methods for Handling Binary Files</h3>
<p className="lesson-p">
    <code>open(file, 'rb')</code>: Opens a file in binary read mode. <br />
    <code>open(file, 'wb')</code>: Opens a file in binary write mode. <br />
    <code>file.read()</code>: Reads the content of a binary file. <br />
    <code>file.write(data)</code>: Writes data to a binary file.
</p>
            <pre style={style.codeBlock}>
                {`# Reading a binary file
with open('image.jpg', 'rb') as file:
    data = file.read()

# Writing to a binary file
with open('copy.jpg', 'wb') as file:
    file.write(data)`}
            </pre>


<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Try these exercises to practice your file handling skills with complex formats.</p>
<ol>
    <li>
        Read a CSV file named 'data.csv' and print each row.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
import csv
with open('data.csv', 'r') as file:
    reader = csv.reader(file)
    for row in reader:
        print(row)`}
        </pre>}
    </li>
    <li>
        Write a Python dictionary to a JSON file named 'data.json'.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
import json
data = {'name': 'Alice', 'age': 30}
with open('data.json', 'w') as file:
    json.dump(data, file)`}
        </pre>}
    </li>
    <li>
        Read a JSON file named 'config.json' and print the contents.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
import json
with open('config.json', 'r') as file:
    data = json.load(file)
    print(data)`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 11 and Preview of Module 12</h2>
            <p className="lesson-p">This module provided an introduction to intermediate file handling techniques in Python, covering CSV, JSON, and binary files, crucial for handling various data types in real-world applications.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module11Lesson;
