import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Module1Lesson.css';

const Module13Lesson = () => {
    const [showSolution1, setShowSolution1] = useState(false);
    const [showSolution2, setShowSolution2] = useState(false);
    const [showSolution3, setShowSolution3] = useState(false);
    const [showSolution4, setShowSolution4] = useState(false);
    const [showSolution5, setShowSolution5] = useState(false);

const style = {
    container: {
        lineHeight: '1.6',
    },
    code: {
        backgroundColor: '#3b3f4a', // Darker background for inline code
        padding: '2px 4px',
        color: '#d1d5db', // Light color for text
    },
codeBlock: {
    backgroundColor: '#04081a', // Darker shade of blue
    color: '#f8f8f8', // Light text color for contrast
    fontSize: '14px', // Adjust font size as needed
    lineHeight: '1.5', // Adjust line height for readability
    padding: '10px',
    borderRadius: '20px',
    overflowX: 'auto', // Horizontal scroll for long lines
    margin: '10px 0', // Margin top and bottom
}
,
    link: {
        color: '#386ffc',
    },
};
    
          useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="lesson-page">
            <div className="lesson-navigation-buttons">
<a href="#" className="lesson-home-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/';
}}>
    reventt
</a>

<a href="#" className="lesson-back-button" onClick={(e) => {
    e.preventDefault();
    window.location.href = '/dashboard';
}}>
    Back
</a>

            </div>

        <div style={style.container} className="lesson-container">
            <h1 className="lesson-h1">Module 13: Functional Programming in Python</h1>

            <h2 className="lesson-h2">Introduction to Functional Programming</h2>
            <p className="lesson-p">Functional programming is a programming paradigm where programs are constructed by applying and composing functions. It emphasizes the use of pure functions and avoiding shared state and mutable data.</p>

            <h3 className="lesson-h3">Pure Functions</h3>
            <p className="lesson-p">Pure functions are a central concept in functional programming. They are functions that always produce the same output for the same set of inputs and do not cause any observable side effects, such as modifying a global state or altering input values. This predictability makes pure functions easier to debug and test, as their behavior is consistent and isolated from the rest of the program. The deterministic nature of pure functions also enhances code readability and maintainability.</p>
            <pre style={style.codeBlock}>
                {`# Example of a pure function
def add(a, b):
    return a + b

result = add(2, 3)
print(result)  # Output: 5`}
            </pre>

            <h3 className="lesson-h3">Higher-Order Functions</h3>
            <p className="lesson-p">Higher-order functions are functions that can accept other functions as arguments or return them as their result. This powerful concept allows for more abstract ways of manipulating and combining functions, thereby increasing the flexibility and reusability of code. Higher-order functions are particularly useful for operations that are generic across different types of data, allowing the same logic to be applied in various contexts simply by changing the function argument.</p>
            <pre style={style.codeBlock}>
                {`# Example of a higher-order function
def apply_function(func, value):
    return func(value)

result = apply_function(lambda x: x * 2, 5)
print(result)  # Output: 10`}
            </pre>

            <h3 className="lesson-h3">Lambda Functions</h3>
            <p className="lesson-p">Lambda functions in Python are small, anonymous functions defined with the <code>lambda</code> keyword. These functions are concise and can be written in a single line. Lambda functions are often used in situations where a simple function is required for a short period, like as an argument to higher-order functions. Their simplicity and inline nature make them ideal for simple operations that need to be performed quickly and without the need for a full function definition.</p>
            <pre style={style.codeBlock}>
                {`# Example of a lambda function
double = lambda x: x * 2
print(double(5))  # Output: 10`}
            </pre>

            <h3 className="lesson-h3">Map, Filter, and Reduce</h3>
            <p className="lesson-p">The <code>map</code>, <code>filter</code>, and <code>reduce</code> functions are staples of functional programming in Python, used for transforming, selecting, and aggregating data, respectively. <code>map</code> applies a function to all items in an input list, <code>filter</code> creates a list of elements for which a function returns true, and <code>reduce</code> cumulatively applies a function to items of a sequence to reduce it to a single value. These functions embody the declarative nature of functional programming, allowing programmers to focus on what should be done, rather than how it should be done.</p>
            <pre style={style.codeBlock}>
                {`# Using map and filter
numbers = [1, 2, 3, 4, 5]
doubled = map(lambda x: x * 2, numbers)
even = filter(lambda x: x % 2 == 0, doubled)
print(list(even))  # Output: [4, 8]

# Using reduce
from functools import reduce
sum = reduce(lambda a, b: a + b, numbers)
print(sum)  # Output: 15`}
            </pre>

                        <h3 className="lesson-h3">Iterators</h3>
<p className="lesson-p">
    Iterators are objects in Python that contain a countable number of values and can be iterated upon. An iterator can be created from iterable objects like lists, tuples, and strings. Python iterators implement two special methods, <code>__iter__()</code> and <code>__next__()</code>, to allow them to iterate through their elements. Iterators are used extensively in Python and are a fundamental aspect of loops and many Python built-in functions. They provide a way to access elements of a collection without using indices, offering a clean and concise way of looping through sequences.
</p>
<ul>
    <li><strong>Iterable Objects</strong>: Collections that can be looped over (e.g., lists, tuples).</li>
    <li><strong>Iterator Protocol</strong>: The implementation of <code>__iter__()</code> and <code>__next__()</code> methods.</li>
    <li><strong>Looping Through Collections</strong>: Iterators are used in loops to access elements.</li>
</ul>
<pre style={style.codeBlock}>
    {`# Example of creating an iterator from an iterable
my_list = [1, 2, 3, 4]
my_iter = iter(my_list)

# Iterating using next()
print(next(my_iter))  # Output: 1
print(next(my_iter))  # Output: 2
# ... and so on until the end of the list`}
</pre>

            <h3 className="lesson-h3">Generators</h3>
<p className="lesson-p">
    Generators in Python are a simple way of creating iterators. They allow you to declare a function that behaves like an iterator, i.e., it can be used in a for loop. Generators are written just like a regular function but use the <code>yield</code> statement to return data. Each time <code>yield</code> is called, the generator resumes where it left off (it remembers all the data values and which statement was last executed). This feature makes generators a powerful tool for creating iterators, especially when dealing with large datasets or infinite sequences, as they are more memory-efficient than using lists.
</p>
<ul>
    <li><strong>Yield Statement</strong>: Used to turn a regular function into a generator.</li>
    <li><strong>Memory Efficiency</strong>: Only one item is processed at a time, reducing memory usage.</li>
    <li><strong>Stateful Iteration</strong>: Generators remember their state during iteration.</li>
</ul>
<pre style={style.codeBlock}>
    {`# Example of a generator function
def count_up_to(max):
    count = 1
    while count <= max:
        yield count
        count += 1

counter = count_up_to(5)
for number in counter:
    print(number)
    # Output: 1, 2, 3, 4, 5`}
</pre>


<h2 className="lesson-h2">Practice Exercises</h2>
<p className="lesson-p">Enhance your functional programming skills with these exercises.</p>
<ol>
    <li>
        Write a lambda function to compute the square of a number and apply it to a list of numbers.
        <button onClick={() => setShowSolution1(!showSolution1)} className="lesson-button">{showSolution1 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution1 && <pre style={style.codeBlock}>
            {`# Solution
numbers = [1, 2, 3, 4]
squared = map(lambda x: x ** 2, numbers)
print(list(squared))  # Output: [1, 4, 9, 16]`}
        </pre>}
    </li>
    <li>
        Use the 'filter' function to extract even numbers from a list.
        <button onClick={() => setShowSolution2(!showSolution2)} className="lesson-button">{showSolution2 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution2 && <pre style={style.codeBlock}>
            {`# Solution
numbers = [1, 2, 3, 4, 5, 6]
even_numbers = filter(lambda x: x % 2 == 0, numbers)
print(list(even_numbers))  # Output: [2, 4, 6]`}
        </pre>}
    </li>
    <li>
        Demonstrate the use of 'reduce' to find the product of numbers in a list.
        <button onClick={() => setShowSolution3(!showSolution3)} className="lesson-button">{showSolution3 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution3 && <pre style={style.codeBlock}>
            {`# Solution
from functools import reduce
numbers = [1, 2, 3, 4]
product = reduce(lambda a, b: a * b, numbers)
print(product)  # Output: 24`}
        </pre>}
    </li>
    <li>
        Create an iterator from a list and use it to iterate through the list.
        <button onClick={() => setShowSolution4(!showSolution4)} className="lesson-button">{showSolution4 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution4 && <pre style={style.codeBlock}>
            {`# Solution
my_list = [1, 2, 3, 4]
my_iter = iter(my_list)
while True:
    try:
        print(next(my_iter))
    except StopIteration:
        break
# Output: 1 2 3 4`}
        </pre>}
    </li>
    <li>
        Write a generator function that yields the first n numbers of the Fibonacci sequence.
        <button onClick={() => setShowSolution5(!showSolution5)} className="lesson-button">{showSolution5 ? "Hide Solution" : "Show Solution"}</button>
        {showSolution5 && <pre style={style.codeBlock}>
            {`# Solution
def fibonacci(n):
    a, b = 0, 1
    for i in range(n):
        yield a
        a, b = b, a + b

for val in fibonacci(5):
    print(val)
# Output: 0 1 1 2 3`}
        </pre>}
    </li>
</ol>


            <h2 className="lesson-h2">Recap of Module 13 and Preview of Module 14</h2>
            <p className="lesson-p">This module explored functional programming in Python, covering pure functions, higher-order functions, lambda expressions, and the use of `map`, `filter`, and `reduce`. These concepts offer a different approach to problem-solving and can complement object-oriented programming practices.</p>
            </div>

                        <div className="end-of-lesson-dashboard-button-container">
                <button className="end-of-lesson-dashboard-button" onClick={() => window.location.href = '/dashboard'}>
    Return to Dashboard
</button>

            </div>
        </div>
    );
};

export default Module13Lesson;
