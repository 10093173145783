import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { fetchAndUpdateUserData } from '../utils/userService';
import './Success.css';

const Success = () => {
    const [sessionInfo, setSessionInfo] = useState(null);
    const location = useLocation();

    const { user, login } = useContext(AuthContext);

    useEffect(() => {
        const fetchSessionInfo = async () => {
            const params = new URLSearchParams(location.search);
            const sessionId = params.get('session_id');
            
            try {
                //const response = await axios.get(`http://localhost:4000/order/success?session_id=${sessionId}`);
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/order/success?session_id=${sessionId}`);
                console.log(response.data)
                setSessionInfo(response.data.session);

                if (response.data.session) {
                    await fetchAndUpdateUserData(user.googleId, login);
                }
            } catch (error) {
                console.error('Error fetching session info:', error);
                // Handle error
            }
        };

        fetchSessionInfo();
        console.log('1');
    }, []);
    console.log('2');

    if (!sessionInfo) {
        //return <div>Loading...</div>;
        return (
            <div class="screen-loading">
            <p className="success-page-nav-button">reventt</p>
            <div class="loader">
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
            </div>

            </div>
        );
    }
    console.log('3');

return (
    <div className="success-page">
        <a href="/" className="success-page-nav-button">reventt</a>
        <div className="gradient-canvas-success">
        <div className="success-container">
            <h1 className="success-h1">Payment Successful</h1>
            <p className="success-p">Thank you for your purchase! We've sent the details of your transaction to your email.</p>
            <div className="success-button-container">
                <Link to="/dashboard" className="success-button">Go to Dashboard</Link>
            </div>
            </div>
            </div>
    </div>
);

};

export default Success;
